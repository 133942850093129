import { gql } from "@apollo/client";

export const PROTOCOL_ENTITY = gql`
  fragment ProtocolEntity on ProtocolEntity {
    _id
    disabled
    type
    section
    zaehler_strom
    zaehler_heizung
    zaehler_wasser
    keys_briefkasten
    keys_haus
    keynumber
    created_at
    updated_at
  }
`;
