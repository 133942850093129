import { gql } from "@apollo/client";
import { CONTACT_ENTITY } from "../../entities/contactEntity";
import { CUSTOMER_ENTITY } from "../../entities/customerEntity";

export const UPDATE_CONTACT = gql`
  mutation updateContact($input: ContactUpdateInput!, $id: String!) {
    updateContact(input: $input, id: $id) {
        ...ContactEntity
        customer {
          ...CustomerEntity
        }
    }
  }
  ${CONTACT_ENTITY},${CUSTOMER_ENTITY}
`;
