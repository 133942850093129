// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullpageloader_loadingWrapper__VEED1 {
  left: 0;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/common/FullPageLoader/fullpageloader.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,MAAM;EACN,aAAa;EACb,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".loadingWrapper {\n  left: 0;\n  top: 0;\n  z-index: 9999;\n  height: 100%;\n  width: 100%;\n  background-color: white;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingWrapper": `fullpageloader_loadingWrapper__VEED1`
};
export default ___CSS_LOADER_EXPORT___;
