import { Box, Divider, Paper, Stack } from "@mui/material";
import Section from "../Section";
import { useState } from "react";
import EditProject from "../EditForms/EditProject";
import EditField from "../EditForms/EditField";
import EditBuilding from "../EditForms/EditBuilding";
import EditApartment from "../EditForms/EditApartment";
import { useParams } from "react-router-dom";

const EditViews = {
  project: EditProject,
  field: EditField,
  building: EditBuilding,
  apartment: EditApartment,
};

const EditSection = (props) => {
  const routeParams = useParams();

  const [data, setData] = useState(null);

  const CurrentView = EditViews[routeParams.section] ?? null;

  return (
    <Paper>
      <Stack direction={"column"}>
        <Box>
          <Section
            section={routeParams.section}
            id={routeParams.id}
            onDataChange={(e) => setData(e)}
          />
        </Box>
        <Divider />
        <Box p={1}>{data && <CurrentView data={data} />}</Box>
      </Stack>
    </Paper>
  );
};

export default EditSection;
