import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_PROJECTS } from "../queries/Project/getAllProjects";
import { CREATE_PROJECT } from "../mutations/Project/createProject";
import { UPDATE_PROJECT } from "../mutations/Project/updateProject";
import { REMOVE_PROJECT } from "../mutations/Project/removeProject";
import { ON_PROJECTS_CHANGED } from "../subscriptions/onProjectsChanged";
import { GET_PROJECT_BY_ID } from "../queries/Project/getProjectById";

const query = GET_ALL_PROJECTS;
const createMutation = CREATE_PROJECT;
const updateMutation = UPDATE_PROJECT;
const removeMutation = REMOVE_PROJECT;
const getByIdMutation = GET_PROJECT_BY_ID;
const subscription = ON_PROJECTS_CHANGED;

const entityName = "project";

export const useProjects = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onProjectsChanged);
      queryRefetch();
    },
  });

  const executeProjectQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllProjects);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError ||
      getByIdMutationError,
    projects: data,
    executeProjectQuery,
    addProject: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateProject: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeProject: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getProjectById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
