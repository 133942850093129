import { gql } from "@apollo/client";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";
import { USER_ENTITY } from "../../entities/userEntity";
import { ENTRY_ENTITY } from "../../entities/entryEntity";
import { FILE_ENTITY } from "../../entities/fileEntity";
import { CONTACT_ENTITY } from "../../entities/contactEntity";
import { CUSTOMER_ENTITY } from "../../entities/customerEntity";
import { MAIL_ENTITY } from "../../entities/mailEntity";

export const GET_ENTRY_BY_ID = gql`
  query getEntryById($id: String!){
    getEntryById(id: $id) {
        ...EntryEntity
        user {
            ...UserEntity
        }
        protocol {
            ...ProtocolEntity
        }
        files {
            ...FileEntity
        }
        contacts {
            ...ContactEntity
            customer {
              ...CustomerEntity
            }
        }
        mails {
            ...MailEntity
            user {
              ...UserEntity
            }
        }
    }
  }
  ${ENTRY_ENTITY},${USER_ENTITY},${PROTOCOL_ENTITY},${FILE_ENTITY},${CONTACT_ENTITY},${CUSTOMER_ENTITY},${MAIL_ENTITY}
`;
