import { gql } from "@apollo/client";
import { CUSTOMER_ENTITY } from "../entities/customerEntity";
import { CONTACT_ENTITY } from "../entities/contactEntity";

export const ON_CUSTOMERS_CHANGED = gql`
  subscription {
    onCustomersChanged {
        ...CustomerEntity
        contacts {
          ...ContactEntity
        }
    }
  }
  ${CUSTOMER_ENTITY},${CONTACT_ENTITY}
`;
