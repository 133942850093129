import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_CUSTOMERS } from "../queries/Customer/getAllCustomers";
import { CREATE_CUSTOMER } from "../mutations/Customer/createCustomer";
import { UPDATE_CUSTOMER } from "../mutations/Customer/updateCustomer";
import { REMOVE_CUSTOMER } from "../mutations/Customer/removeCustomer";
import { ON_CUSTOMERS_CHANGED } from "../subscriptions/onCustomersChanged";
import { GET_CUSTOMER_BY_ID } from "../queries/Customer/getCustomerById";

const query = GET_ALL_CUSTOMERS;
const createMutation = CREATE_CUSTOMER;
const updateMutation = UPDATE_CUSTOMER;
const removeMutation = REMOVE_CUSTOMER;
const getByIdMutation = GET_CUSTOMER_BY_ID;
const subscription = ON_CUSTOMERS_CHANGED;

const entityName = "customer";

export const useCustomers = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onCustomersChanged);
      queryRefetch();
    },
  });

  const executeCustomerQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllCustomers);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError ||
      getByIdMutationError,
    customers: data,
    executeCustomerQuery,
    addCustomer: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateCustomer: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeCustomer: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getCustomerById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
