// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects_fieldWrapper__7Q8qT {
  background-color: #f6f8fc !important;
  border: 1px solid #f0f1f8 !important;
}
.projects_buildingWrapper__x-rk8 {
  background-color: #eaeef5 !important;
  border: 1px solid #e0e6ee !important;
}
.projects_apartmentWrapper__tylOT {
  background-color: #ffffff !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/Projects/projects.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".fieldWrapper {\n  background-color: #f6f8fc !important;\n  border: 1px solid #f0f1f8 !important;\n}\n.buildingWrapper {\n  background-color: #eaeef5 !important;\n  border: 1px solid #e0e6ee !important;\n}\n.apartmentWrapper {\n  background-color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldWrapper": `projects_fieldWrapper__7Q8qT`,
	"buildingWrapper": `projects_buildingWrapper__x-rk8`,
	"apartmentWrapper": `projects_apartmentWrapper__tylOT`
};
export default ___CSS_LOADER_EXPORT___;
