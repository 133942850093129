import { gql } from "@apollo/client";
import { FIELD_ENTITY } from "../entities/fieldEntity";
import { BUILDING_ENTITY } from "../entities/buildingEntity";
import { APARTMENT_ENTITY } from "../entities/apartmentEntity";
import { PROTOCOL_ENTITY } from "../entities/protocolEntity";
import { PROJECT_ENTITY } from "../entities/projectEntity";

export const ON_APARTMENTS_CHANGED = gql`
  subscription {
    onApartmentsChanged {
        ...ApartmentEntity
        building {
          ...BuildingEntity
          field {
            ...FieldEntity
            project {
              ...ProjectEntity
            }
          }
        }
        protocols {
          ...ProtocolEntity
        }
    }
  }
  ${APARTMENT_ENTITY},${BUILDING_ENTITY},${PROTOCOL_ENTITY},${FIELD_ENTITY},${PROJECT_ENTITY}
`;
