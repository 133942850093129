import { gql } from "@apollo/client";

export const ENTRY_ENTITY = gql`
  fragment EntryEntity on EntryEntity {
    _id
    topic
    deadline
    state
    hidden
    level
    room
    section
    hours
    workers
    comment
    created_at
    updated_at
  }
`;
