import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../common/FullPageLoader";
import { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Section from "../Section";
import moment from "moment";
import { ProtocolTypes, SectionTypeText } from "../../types";
import { IoFilter } from "react-icons/io5";
import Entrys from "../Entrys/Entrys";
import { uniq } from "../../uniq";
import { useContacts } from "../../apollo/hooks/useContacts";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useCustomers } from "../../apollo/hooks/useCustomers";
import { useProtocols } from "../../apollo/hooks/useProtocols";
import { useApartments } from "../../apollo/hooks/useApartments";
import { useProjects } from "../../apollo/hooks/useProjects";
import { useBuildings } from "../../apollo/hooks/useBuildings";
import { useFields } from "../../apollo/hooks/useFields";
import SendEntries from "../Entrys/SendEntries";

const ProtocolPaper = ({
  protocol,
  noShadow,
  showAll,
  showSent,
  selectedCustomer,
  selectedContact,
  selectedLevel,
  selectedGewerk,
  removeProtocol,
  disableProtocol,
}) => {
  const router = useNavigate();
  const [filteredEntries, setFilteredEntries] = useState([]);

  const renderObject = (protocol) => {
    switch (protocol.section) {
      case "APARTMENT":
        return protocol.apartment.name;
      case "BUILDING":
        return protocol.building.name;
      case "FIELD":
        return protocol.field.name;
      default:
        return protocol.project.name;
    }
  };

  const EditProtocolForm = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleRemoveProtocol = () => {
      if (window.confirm(`Möchtest du das Protokoll löschen?`)) {
        removeProtocol(props.protocol._id);
      }
    };
    const handleDisableProtocol = () => {
      if (
        window.confirm(
          `Möchtest du das Protokoll ${props.protocol.disabled ? "aus dem" : "in den"} Papierkorb verschieben?`,
        )
      ) {
        const payload = {
          disabled: !props.protocol.disabled,
        };
        disableProtocol(payload, props.protocol._id);
      }
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Stack direction={"row"} spacing={1}>
          {showAll && protocol.type === "MAENGEL" && (
            <SendEntries protocol={protocol} entries={filteredEntries} />
          )}
          <Button onClick={() => router(`/protocols/${props.protocol._id}`)}>
            Ansehen
          </Button>
          <Fab size={"small"} color={"secondary"} onClick={handleClick}>
            <BiDotsVerticalRounded />
          </Fab>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple onClick={handleDisableProtocol}>
            <Typography
              color={"secondary"}
            >{`${props.protocol.disabled ? "Aus dem" : "In den"} Papierkorb`}</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem disableRipple onClick={handleRemoveProtocol}>
            <Typography color={"secondary"}>{"Protokoll Löschen"}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  if (!protocol) return false;
  return (
    <Box pt={showAll ? (filteredEntries.length > 0 ? 1 : 0) : 1}>
      <Paper
        sx={{
          boxShadow: noShadow && "none",
          display: showAll && (filteredEntries.length > 0 ? "block" : "none"),
        }}
      >
        <Stack direction={"column"} spacing={0.5}>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"space-between"}
            p={2}
            pb={1}
          >
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"column"}>
                <Typography variant={"h7"}>
                  {`${ProtocolTypes[protocol?.type]}`}
                </Typography>
                <Typography variant={"body2"}>
                  {`${SectionTypeText[protocol?.section]} ${renderObject(protocol)}`}
                </Typography>
              </Stack>
            </Stack>
            <EditProtocolForm protocol={protocol} />
          </Stack>
          {showAll && (
            <Entrys
              onFilterChanged={(val) => setFilteredEntries(val)}
              selectedCustomer={selectedCustomer}
              selectedContact={selectedContact}
              selectedLevel={selectedLevel}
              selectedGewerk={selectedGewerk}
              showAll
              showSent={showSent}
              minified
              noEdit
              protocolId={protocol._id}
            />
          )}
          <Box p={0.5} pt={0}>
            <Card>
              <CardContent>
                <Stack direction={"row"} justifyContent={"space-between"} p={1}>
                  <Typography variant={"body2"}>
                    {`Protokoll erstellt von ${protocol.user.prename} ${protocol.user.surname} am ${moment(protocol.created_at).format("DD.MM.YYYY HH:mm")}`}
                  </Typography>
                  <Typography variant={"body2"}>
                    {`${protocol.entrys.length} ${protocol.entrys.length === 1 ? "Eintrag" : "Einträge"}`}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

const Protocols = (props) => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedGewerk, setSelectedGewerk] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [typeFilter, setTypeFilter] = useState("ALL");

  const routeParams = useParams();
  const { contacts, executeContactQuery, error: contactsError } = useContacts();

  const { me, executeUserSelfQuery, error: usersError } = useUsers();

  const {
    customers,
    executeCustomerQuery,
    error: customersError,
  } = useCustomers();

  const {
    protocols,
    removeProtocol,
    disableProtocol,
    executeProtocolQuery,
    error: protocolsError,
  } = useProtocols();
  const { projects, executeProjectQuery, error: projectsError } = useProjects();
  const { fields, executeFieldQuery, error: fieldsError } = useFields();
  const {
    buildings,
    executeBuildingQuery,
    error: buildingsError,
  } = useBuildings();
  const {
    apartments,
    executeApartmentQuery,
    error: apartmentsError,
  } = useApartments();
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showSent, setShowSent] = useState(false);

  useEffect(() => {
    executeContactQuery();
    executeUserSelfQuery();
    executeCustomerQuery();
    executeProtocolQuery();
    executeApartmentQuery();
    executeProjectQuery();
    executeBuildingQuery();
    executeFieldQuery();
  }, []);

  useEffect(() => {
    if (props.myTasks && contacts) {
      setShowAll(true);
      setSelectedContact(
        contacts.find(
          (contact) =>
            contact.prename === me.prename && contact.surname === me.surname,
        ) || "null",
      );
    }
  }, [props.myTasks, me, contacts]);

  if (
    !protocols ||
    !fields ||
    !apartments ||
    !buildings ||
    !projects ||
    !customers ||
    !contacts ||
    !apartments
  )
    return <FullPageLoader position={"relative"} />;
  if (
    protocolsError ||
    contactsError ||
    usersError ||
    buildingsError ||
    apartmentsError ||
    customersError ||
    projectsError ||
    fieldsError
  )
    return <FullPageLoader error />;

  const FilterButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Box display={"flex"} alignItems={"center"} sx={{ height: "100%" }}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Button size={"small"} color={"primary"} onClick={handleClick}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <IoFilter />
              <Box>{filterDisabled ? "Archiv" : "Aktiv"}</Box>
            </Stack>
          </Button>
        </Stack>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <Stack direction={"column"} spacing={1} p={1}>
              <Button
                onClick={() => setFilterDisabled(false)}
                disabled={!filterDisabled}
              >
                Aktiv
              </Button>
              <Button
                onClick={() => setFilterDisabled(true)}
                disabled={filterDisabled}
              >
                Archiv
              </Button>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  const FilterType = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const FilterNames = {
      ALL: "Alle",
      MAENGEL: "Mängelprotokolle",
      BEGEHUNG: "Begehungsprotokolle",
      TAGESBERICHT: "Bautageberichte",
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Stack direction={"column"} spacing={1} alignItems={"start"}>
          <Button size={"small"} color={"primary"} onClick={handleClick}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <IoFilter />
              <Box>{FilterNames[typeFilter]}</Box>
            </Stack>
          </Button>
        </Stack>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper sx={{ minWidth: 250 }}>
            <Stack direction={"column"} spacing={1} p={1}>
              <Button
                disabled={typeFilter === "ALL"}
                onClick={() => setTypeFilter("ALL")}
              >
                {FilterNames.ALL}
              </Button>
              <Button
                disabled={typeFilter === "MAENGEL"}
                onClick={() => setTypeFilter("MAENGEL")}
              >
                {FilterNames.MAENGEL}
              </Button>
              <Button
                disabled={typeFilter === "BEGEHUNG"}
                onClick={() => setTypeFilter("BEGEHUNG")}
              >
                {FilterNames.BEGEHUNG}
              </Button>
              <Button
                disabled={typeFilter === "TAGESBERICHT"}
                onClick={() => setTypeFilter("TAGESBERICHT")}
              >
                {FilterNames.TAGESBERICHT}
              </Button>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  const FilterForm = () => {
    const handleSelectCustomer = (event, index) => {
      if (index) {
        setSelectedCustomer(customers.find((x) => x._id === index._id));
      } else {
        setSelectedCustomer(null);
      }
      setSelectedContact(null);
    };
    const handleSelectContact = (event, index) => {
      if (index) {
        setSelectedContact(contacts.find((x) => x._id === index._id));
      } else {
        setSelectedContact(null);
      }
    };
    const handleSelectGewerk = (event, index) => {
      if (index) {
        setSelectedGewerk(index);
      } else {
        setSelectedGewerk(null);
      }
    };

    return (
      <Stack direction={"column"} spacing={1} p={1}>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            id="customer"
            onChange={handleSelectCustomer}
            options={customers}
            fullWidth
            value={selectedCustomer}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Firma filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
          <Autocomplete
            id="contact"
            onChange={handleSelectContact}
            options={selectedCustomer?.contacts || []}
            disabled={!selectedCustomer}
            fullWidth
            value={selectedContact}
            getOptionLabel={(option) =>
              option.gender + " " + option.prename + " " + option.surname
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Kontakt filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            id="gewerk"
            onChange={handleSelectGewerk}
            options={uniq(
              [].concat(...contacts.map((contact) => contact.gewerk)),
            )}
            fullWidth
            value={selectedGewerk}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Gewerk filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
        </Stack>
      </Stack>
    );
  };

  const filteredProtocols = (protocolArray) => {
    return protocolArray
      .filter((protocol) =>
        protocols.find(
          (p) => p._id === protocol._id && p.disabled === filterDisabled,
        ),
      )
      .filter((protocol) =>
        typeFilter === "ALL" ? true : protocol.type === typeFilter,
      );
  };

  const ProjectProtocols = () => {
    let project = projects.find((project) => project._id === routeParams.id);
    return filteredProtocols(
      protocols.filter((protocol) => protocol.project?._id === project._id),
    ).filter((pr) => pr.section === "PROJECT").length > 0 ? (
      <Stack direction={"column"} spacing={0}>
        <Stack direction={"column"} spacing={0}>
          {filteredProtocols(
            protocols.filter(
              (protocol) => protocol.project?._id === project._id,
            ),
          )
            .filter((pr) => pr.section === "PROJECT")
            .map((protocol, index) => (
              <ProtocolPaper
                noShadow
                key={index}
                protocol={protocols.find((p) => p?._id === protocol?._id)}
                selectedContact={selectedContact}
                selectedCustomer={selectedCustomer}
                selectedGewerk={selectedGewerk}
                selectedLevel={selectedLevel}
                showAll={showAll}
                showSent={showSent}
                removeProtocol={removeProtocol}
                disableProtocol={disableProtocol}
              />
            ))}
        </Stack>
        <FieldProtocols fieldsArray={project?.fields} />
      </Stack>
    ) : (
      <FieldProtocols fieldsArray={project?.fields} />
    );
  };

  const FieldProtocols = ({ fieldsArray }) => {
    return fieldsArray.map((field, index) =>
      filteredProtocols(
        protocols.filter(
          (protocol) =>
            protocol.field?._id === fields.find((x) => x._id === field._id)._id,
        ),
      ).length > 0 ? (
        <Stack direction={"column"} spacing={0}>
          <Stack direction={"column"} spacing={0}>
            {filteredProtocols(
              protocols.filter(
                (protocol) =>
                  protocol.field?._id ===
                  fields.find((x) => x._id === field._id)._id,
              ),
            ).map((protocol, index) => (
              <ProtocolPaper
                noShadow
                key={index}
                protocol={protocols.find((p) => p?._id === protocol?._id)}
                selectedContact={selectedContact}
                selectedCustomer={selectedCustomer}
                selectedGewerk={selectedGewerk}
                selectedLevel={selectedLevel}
                showAll={showAll}
                showSent={showSent}
                removeProtocol={removeProtocol}
                disableProtocol={disableProtocol}
              />
            ))}
          </Stack>
          <BuildingProtocols
            buildingsArray={fields.find((x) => x._id === field._id).buildings}
          />
        </Stack>
      ) : (
        <Stack direction={"column"} spacing={0} key={index}>
          <BuildingProtocols
            buildingsArray={fields.find((x) => x._id === field._id).buildings}
          />
        </Stack>
      ),
    );
  };

  const BuildingProtocols = ({ buildingsArray }) => {
    return buildingsArray.map((building, index) =>
      filteredProtocols(
        protocols.filter(
          (protocol) =>
            protocol.building?._id ===
            buildings.find((x) => x._id === building._id)._id,
        ),
      ).length > 0 ? (
        <Stack key={index} direction={"column"} spacing={0}>
          <Stack direction={"column"} spacing={0}>
            {filteredProtocols(
              protocols.filter(
                (protocol) =>
                  protocol.building?._id ===
                  buildings.find((x) => x._id === building._id)._id,
              ),
            ).map((protocol, index) => (
              <ProtocolPaper
                noShadow
                key={index}
                protocol={protocols.find((p) => p?._id === protocol?._id)}
                selectedContact={selectedContact}
                selectedCustomer={selectedCustomer}
                selectedGewerk={selectedGewerk}
                selectedLevel={selectedLevel}
                showAll={showAll}
                showSent={showSent}
                removeProtocol={removeProtocol}
                disableProtocol={disableProtocol}
              />
            ))}
          </Stack>
          <ApartmentProtocols
            apartmentsArray={
              buildings.find((x) => x._id === building._id).apartments
            }
          />
        </Stack>
      ) : (
        <Stack direction={"column"} spacing={0} key={index}>
          <ApartmentProtocols
            apartmentsArray={
              buildings.find((x) => x._id === building._id).apartments
            }
          />
        </Stack>
      ),
    );
  };

  const ApartmentProtocols = ({ apartmentsArray }) => {
    return apartmentsArray.map((apartment, index) =>
      filteredProtocols(
        protocols.filter(
          (protocol) =>
            protocol.apartment?._id ===
            apartments.find((x) => x._id === apartment._id)._id,
        ),
      ).map((protocol, index) => (
        <ProtocolPaper
          noShadow
          key={index}
          protocol={protocols.find((p) => p?._id === protocol?._id)}
          selectedContact={selectedContact}
          selectedCustomer={selectedCustomer}
          selectedGewerk={selectedGewerk}
          selectedLevel={selectedLevel}
          showAll={showAll}
          showSent={showSent}
          removeProtocol={removeProtocol}
          disableProtocol={disableProtocol}
        />
      )),
    );
  };

  const SectionProtocols = {
    project: <ProjectProtocols />,
    field: (
      <FieldProtocols
        fieldsArray={fields.filter((field) => field._id === routeParams.id)}
      />
    ),
    building: (
      <BuildingProtocols
        buildingsArray={buildings.filter(
          (building) => building._id === routeParams.id,
        )}
      />
    ),
    apartment: (
      <ApartmentProtocols
        apartmentsArray={apartments.filter(
          (apartment) => apartment._id === routeParams.id,
        )}
      />
    ),
  };

  return (
    <Stack direction={"column"} spacing={0}>
      <Paper>
        <Stack direction={"column"} divider={<Divider />}>
          <Section id={routeParams.id} />
          {!props.myTasks && showAll && <FilterForm />}
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"space-between"}
            p={1}
            alignItems={"start"}
          >
            <Stack direction={"row"} spacing={1} alignItems={"start"}>
              <FilterType />
              <FilterButton />
            </Stack>
            {!props.myTasks && (
              <Stack direction={"column"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAll}
                      onClick={() => setShowAll(!showAll)}
                    />
                  }
                  label={
                    <Stack direction={"column"}>
                      <Typography variant={"body1"}>
                        Einträge anzeigen
                      </Typography>
                      <Typography variant={"body2"}>
                        (Offen / In Bearbeitung, min. 1 Eintrag)
                      </Typography>
                    </Stack>
                  }
                />
                <FormControlLabel
                  disabled={!showAll}
                  control={
                    <Checkbox
                      checked={showSent}
                      onClick={() => setShowSent(!showSent)}
                    />
                  }
                  label={
                    <Stack direction={"column"}>
                      <Typography variant={"body1"}>
                        Versendete anzeigen
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Paper>
      {SectionProtocols[routeParams.section]}
    </Stack>
  );
};

export default Protocols;
