import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    padding: 25,
    flexDirection: "column",
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    border: "1px solid #000000",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold",
    borderBottom: "1px solid #000000",
  },
  headline: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
  },
  zaehlerstaende: {
    padding: 10,
    backgroundColor: "#e0e0e0",
    width: "100%",
    marginBottom: 10,
    display: "flex",
    rowGap: "10px",
    flexDirection: "column",
  },
  zaehlerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  zaehlerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  zaehlerTitle: {
    fontSize: 12,
  },
  zaehlerSubtitle: {
    fontSize: 9,
  },
  signatureView: {
    flexWrap: "wrap",
    width: "100%",
    marginTop: "auto",
  },
  signaturePlaceholder: {
    height: 50,
  },
  signatureWrapper: {
    paddingTop: 15,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    gap: 10,
  },
  signatureTextWrapper: {
    paddingTop: 5,
    borderTop: "1px solid black",
  },
  signatureImage: {
    height: 50,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: 200,
    textAlign: "center",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    gap: 10,
  },
  imageContainer: {
    flex: "1 0 47%",
    padding: 10,
    border: "1px solid black",
    alignItems: "center",
  },
  headlineTitle: {
    fontSize: 14,
  },
  headlineSubtitle: {
    fontSize: 9,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  row2: {
    backgroundColor: "#f0f2f5",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  level: {
    padding: 5,
    width: "20%",
  },
  room: {
    padding: 5,
    width: "20%",
  },
  comment: {
    padding: 5,
    width: "40%",
  },
  files: {
    padding: 5,
    width: "20%",
  },
  image: {
    width: 200,
    marginBottom: 10,
  },
});

function PDF_Uebergabe(props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headline}>
          <Text style={styles.headlineTitle}>{props.title}</Text>
          <Text style={styles.headlineSubtitle}>{props.subtitle}</Text>
        </View>
        <View style={styles.zaehlerstaende}>
          <View style={styles.zaehlerWrapper}>
            <View style={styles.zaehlerContainer}>
              <Text style={styles.zaehlerTitle}>
                {props.protocol.zaehler_strom ?? "---"} kW/h
              </Text>
              <Text style={styles.zaehlerSubtitle}>
                Zählerstand Strom in kW/h
              </Text>
            </View>
            <View style={styles.zaehlerContainer}>
              <Text style={styles.zaehlerTitle}>
                {props.protocol.zaehler_heizung ?? "---"} kW/h
              </Text>
              <Text style={styles.zaehlerSubtitle}>
                Zählerstand Heizung in kW/h
              </Text>
            </View>
          </View>
          <View style={styles.zaehlerWrapper}>
            <View style={styles.zaehlerContainer}>
              <Text style={styles.zaehlerTitle}>
                {props.protocol.zaehler_wasser ?? "---"} m³
              </Text>
              <Text style={styles.zaehlerSubtitle}>
                Zählerstand Wasser in m³
              </Text>
            </View>
            <View style={styles.zaehlerContainer}>
              <Text style={styles.zaehlerTitle}>
                {props.protocol.keys_briefkasten ?? "---"} Schlüssel
              </Text>
              <Text style={styles.zaehlerSubtitle}>
                Schlüsselanzahl Briefkasten
              </Text>
            </View>
          </View>
          <View style={styles.zaehlerWrapper}>
            <View style={styles.zaehlerContainer}>
              <Text style={styles.zaehlerTitle}>
                {props.protocol.keys_haus ?? "---"} Schlüssel
              </Text>
              <Text style={styles.zaehlerSubtitle}>Schlüsselanzahl Haus</Text>
            </View>
            <View style={styles.zaehlerContainer}>
              <Text style={styles.zaehlerTitle}>
                {props.protocol.keynumber ?? "---"}
              </Text>
              <Text style={styles.zaehlerSubtitle}>Schlüsselnummer</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.head}>
            <Text style={styles.level}>Level</Text>
            <Text style={styles.room}>Zimmer</Text>
            <Text style={styles.comment}>Bemerkung</Text>
            <Text style={styles.files}>Dateien</Text>
          </View>
          {props.items
            .filter(
              (entry) =>
                entry.state === "OPEN" || entry.state === "IN_PROGRESS",
            )
            .map((item, index) => (
              <View
                style={index % 2 === 0 ? styles.row : styles.row2}
                key={item._id}
              >
                <Text style={styles.level}>{item.level}</Text>
                <Text style={styles.room}>{item.room}</Text>
                <Text style={styles.comment}>{item.comment}</Text>
                <Text style={styles.files}>
                  {item.files.map(
                    (x, i) =>
                      `${index + 1}.${i + 1}${i + 1 !== item.files.length ? "," : ""} `,
                  )}
                </Text>
              </View>
            ))}
        </View>
        {props.withImages && (
          <View break style={styles.imageWrapper}>
            {props.items
              .filter(
                (entry) =>
                  entry.state === "OPEN" || entry.state === "IN_PROGRESS",
              )
              .map((item, index) =>
                item.files.map((x, i) => (
                  <View style={styles.imageContainer}>
                    <Image
                      style={styles.image}
                      src={window.env.FILES_URL + item._id + "/" + x.filename}
                    />
                    <Text>
                      Datei {index + 1}.{i + 1}
                    </Text>
                  </View>
                )),
              )}
          </View>
        )}
        {props.withSignatures && (
          <View style={styles.signatureView}>
            <View style={styles.signatureWrapper}>
              <View style={styles.signatureContainer}>
                {props.kundenSignature ? (
                  <Image
                    style={styles.signatureImage}
                    src={props.kundenSignature}
                  />
                ) : (
                  <View style={styles.signaturePlaceholder} />
                )}
                <Text style={styles.signatureTextWrapper}>
                  Unterschrift Kunde/Firma
                </Text>
              </View>
              <View style={styles.signatureContainer}>
                {props.bauleiterSignature ? (
                  <Image
                    style={styles.signatureImage}
                    src={props.bauleiterSignature}
                  />
                ) : (
                  <View style={styles.signaturePlaceholder} />
                )}
                <Text style={styles.signatureTextWrapper}>
                  Unterschrift Bauleiter
                </Text>
              </View>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
}

export default PDF_Uebergabe;
