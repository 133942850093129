import { gql } from "@apollo/client";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const UPDATE_ENTRY_STATE = gql`
  mutation updateEntryState($input: EntryStateInput!, $id: String!) {
    updateEntryState(input: $input, id: $id) {
      ...EntryEntity
    }
  }
  ${ENTRY_ENTITY}
`;
