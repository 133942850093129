import { gql } from "@apollo/client";
import { MAIL_ENTITY } from "../../entities/mailEntity";

export const SEND_MAIL = gql`
  mutation sendMail($file: Upload!, $input: MailInput!) {
    sendMail(file: $file, input: $input) {
      ...MailEntity
    }
  }
  ${MAIL_ENTITY}
`;
