import { gql } from "@apollo/client";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const UPDATE_ENTRY_HIDDEN = gql`
  mutation updateEntryHidden($input: EntryHiddenInput!, $id: String!) {
    updateEntryHidden(input: $input, id: $id) {
      ...EntryEntity
    }
  }
  ${ENTRY_ENTITY}
`;
