import { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";

const CustomPagination = (props) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastPost = page * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = props.items
    ? props.items.slice(indexOfFirstPost, indexOfLastPost)
    : [];

  useEffect(() => {
    props.onPageChange(currentItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Pagination
        size="large"
        color={"primary"}
        count={Math.ceil(props.items.length / itemsPerPage ?? 0)}
        page={page}
        onChange={handleChangePage}
      />
    </Box>
  );
};

export default CustomPagination;
