import { useEffect, useState } from "react";
import { ProtocolTypes, SectionTypeText } from "../../types";
import PDF from "../common/PDF";
import moment from "moment/moment";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./entrys.module.css";
import Contact from "../common/Contact";
import { IoMdSend } from "react-icons/io";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useContacts } from "../../apollo/hooks/useContacts";
import FullPageLoader from "../common/FullPageLoader";
import SignatureCanvas from "react-signature-canvas";
import { useMails } from "../../apollo/hooks/useMails";

const SendEntries = (props) => {
  const ProtocolSectionName = {
    PROJECT: props.protocol.project?.name,
    FIELD: props.protocol.field?.name,
    BUILDING: props.protocol.building?.name,
    APARTMENT: props.protocol.apartment?.name,
  };

  const { me, executeUserSelfQuery, error: usersError } = useUsers();
  const { contacts, executeContactQuery, error: contactsError } = useContacts();
  const { sendMail, error: mailsError } = useMails();

  const [gracePeriod, setGracePeriod] = useState(false);
  const [sendArray, setSendArray] = useState([]);
  const [step, setStep] = useState(0);
  const [signatureBauleiterRef, setSignatureBauleiterRef] = useState(null);
  const [signatureBauleiterImageUrl, setSignatureBauleiterImageUrl] =
    useState(null);
  const [signatureKundeRef, setSignatureKundeRef] = useState(null);
  const [signatureKundeImageUrl, setSignatureKundeImageUrl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subject, setSubject] = useState();
  const [content, setContent] = useState();
  const open = Boolean(anchorEl);

  const renderContacts = () => {
    const contactArray = [];
    props.entries.map((entry) => {
      return entry.contacts.map((e, i) => {
        if (!contactArray.find((c) => c._id === e._id)) {
          contactArray.push(e);
        }
      });
    });
    return contactArray;
  };

  useEffect(() => {
    executeUserSelfQuery();
    executeContactQuery();
  }, []);

  useEffect(() => {
    setSendArray(renderContacts().filter((c) => c.email));
  }, []);

  if (!me || !contacts) return <FullPageLoader position={"relative"} />;
  if (contactsError || usersError || mailsError)
    return <FullPageLoader error />;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: { xs: "90%", md: "30%" },
    maxHeight: "90vh",
    overflowY: "auto",
    p: 2,
  };

  const handleSetGracePeriod = (enabled) => {
    enabled
      ? setSubject(
          `Nachfristsetzung - ${ProtocolTypes[props.protocol.type]} ${SectionTypeText[props.protocol.section]} ${ProtocolSectionName[props.protocol.section]}`,
        )
      : setSubject(
          `Fristsetzung - ${ProtocolTypes[props.protocol.type]} ${SectionTypeText[props.protocol.section]} ${ProtocolSectionName[props.protocol.section]}`,
        );
    enabled
      ? setContent(
          "Sehr geehrte Damen und Herren,\n" +
            "\n" +
            "bei den mit Ihnen vertraglich vereinbarten Leistungen haben wir Mängel festgestellt, welche umgehend behoben werden müssen. Diese haben wir Ihnen mit dem Schreiben vom $SCHREIBEN angezeigt, wobei sie die genannten Fristenfruchtlos verstreichen ließen.\n\n" +
            "Hierzu erhalten Sie anbei die Mängelliste für $BEREICH erneut, in denen Sie die entsprechenden Leistungen mit Terminsetzung vertragsgerecht herstellen müssen. Bitte halten Sie unbedingt diese genannten Nachfristen ein.\n\n" +
            "Sollten uns oder unseren Nachfolgegewerken durch die nicht vertragsgerechte Erstellung Verzögerungen, Mehraufwand, Schadensersatzansprüche und/ oder der gleichen entstehen oder entstanden sein, müssen wir diese zu Ihren Lasten umlegen. Sollten Sie diese Fristen nicht einhalten, werden wir nach Erteilung einer Nachfrist gegebenenfalls von unserem Kündigungsrecht und/ oder Ersatzvornahme Gebrauch machen müssen.\n\n" +
            "Schadensersatzansprüche und eventuell vereinbarte Vertragsstrafen bleiben davon unberührt.\n\n" +
            "Bitte melden Sie den Mangel mit einem unterschriebenen Nachweis vom Kunden bei uns ab. Gerne können Sie unseren Vordruck hierfür verwenden. Der Mangel gilt andernfalls für uns als nicht abgearbeitet.\n\n" +
            "Mit freundlichen Grüßen,\n" +
            "$NAME\n\n" +
            "Diese Email wurde automatisch generiert. Über diese E-Mail Adresse können keine Rückmeldungen oder Anfragen bearbeitet werden. Wenden Sie sich bitte direkt an Ihren zuständigen Ansprechpartner oder Bauleiter.",
        )
      : setContent(
          "Sehr geehrte Damen und Herren,\n" +
            "\n" +
            "Bei den mit Ihnen vertraglich vereinbarten Leistungen haben wir Mängel festgestellt, welche umgehend behoben werden müssen.\n" +
            "Hierzu erhalten Sie anbei die Mängelliste für $BEREICH in denen Sie die entsprechenden Leistungen mit Terminsetzung vertragsgerecht herstellen müssen.\n" +
            "Bitte halten Sie unbedingt die genannten Fristen ein.\n\n" +
            "Sollten uns oder Nachfolgegewerken durch die nicht vertragsgerechte Erstellung Verzögerung, Mehraufwändungen, Schadenersatzansprüche und der gleichen entstehen oder entstanden sein, müssen wir diese zu Ihren Lasten umlegen.\n" +
            "Sollten Sie diese Fristen nicht einhalten, werden wir nach Erteilung einer Nachfrist ggf. von unserem Kündigungsrecht und oder Ersatzvornahme gebrauch machen müssen.\n" +
            "Schadenersatzansprüche und eventuell vereinbarte Vertragsstrafen bleiben davon unberührt.\n\n" +
            "Bitte melden Sie den Mangel mit einem unterschriebenen Nachweis vom Kunden bei uns ab. Gerne können Sie unseren Vordruck hierfür verwenden. Der Mangel gilt andernfalls für uns als nicht abgearbeitet.\n\n" +
            "Mit freundlichen Grüßen,\n" +
            "$NAME\n\n" +
            "Diese Email wurde automatisch generiert. Über diese E-Mail Adresse können keine Rückmeldungen oder Anfragen bearbeitet werden. Wenden Sie sich bitte direkt an Ihren zuständigen Ansprechpartner oder Bauleiter.",
        );
    setGracePeriod(enabled);
    setStep(step + 1);
  };

  const handleAddToArray = (contact) => {
    if (!sendArray.find((c) => c._id === contact._id))
      setSendArray([...sendArray, contact]);
  };

  const handleRemoveFromArray = (contact) => {
    if (sendArray.find((c) => c._id === contact._id))
      setSendArray(sendArray.filter((a) => a._id !== contact._id));
  };

  const updatedEntries = props.entries
    .filter((entry) => !entry.hidden && moment().isAfter(entry.deadline))
    .map((entry) => {
      const newDeadline = moment(entry.deadline).add(10, "days").toISOString();
      return {
        ...entry,
        deadline: newDeadline,
      };
    });

  const handleSendEmails = async () => {
    const element = (
      <PDF
        title={`${ProtocolTypes[props.protocol.type]} ${SectionTypeText[props.protocol.section]} "${ProtocolSectionName[props.protocol.section]}"`}
        subtitle={`Erstellt am ${moment(props.protocol.created_at).format("DD.MM.YYYY HH:mm")} Uhr`}
        withSignatures
        bauleiterSignature={signatureBauleiterImageUrl}
        kundenSignature={signatureKundeImageUrl}
        withImages
        items={
          gracePeriod
            ? updatedEntries
            : props.entries.filter((entry) => !entry.hidden)
        }
      />
    );
    const myPdf = pdf(element);
    const blob = await myPdf.toBlob(); /*create blob*/
    var file = new File(
      [blob],
      "Protokoll " + ProtocolSectionName[props.protocol.section] + ".pdf",
      {
        lastModified: new Date().getTime(),
      },
    );

    let payload = {
      grace_period: gracePeriod,
      subject: subject,
      content: content
        .replaceAll(
          "$SCHREIBEN",
          moment(
            props.entries
              .filter((entry) => !entry.hidden)[0]
              .mails.filter((mail) => !mail.grace_period)[
              props.entries.filter((entry) => !entry.hidden)[0].mails.length - 1
            ]?.created_at,
          ).format("L"),
        )
        .replaceAll(
          "$BEREICH",
          SectionTypeText[props.protocol.section] +
            " " +
            ProtocolSectionName[props.protocol.section],
        )
        .replaceAll("$NAME", me.prename + " " + me.surname),
      bcc: sendArray.map((c) => c.email.toLowerCase()),
      entrys: props.entries
        .filter((entry) => !entry.hidden)
        .map((entry) => entry._id),
    };

    sendMail(file, payload);
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <Stack direction={"column"} spacing={1}>
            <Button
              onClick={() => handleSetGracePeriod(false)}
              disabled={content && !gracePeriod}
            >
              Frist setzen
            </Button>
            <Button
              onClick={() => handleSetGracePeriod(true)}
              disabled={content && gracePeriod}
            >
              Nachfrist setzen
            </Button>
            <Stack direction={"row"} spacing={1}>
              <Button disabled>Zurück</Button>
              <Button onClick={() => setStep(step + 1)}>Weiter</Button>
            </Stack>
          </Stack>
        );
      case 1:
        return (
          <Stack direction={"column"} spacing={1}>
            <Box px={1}>
              <Typography variant={"h6"}>
                {gracePeriod ? "Nachfristsetzung" : "Fristsetzung"}
              </Typography>
            </Box>
            <TextField
              variant={"filled"}
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              label={"Betreff"}
            />
            <TextField
              variant={"filled"}
              onChange={(e) => setContent(e.target.value)}
              multiline
              rows={6}
              value={content}
              label={"Inhalt"}
            />
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Chip size={"small"} label={"$BEREICH"} />
                <Typography variant={"body2"}>
                  {"= Bereich in der Struktur (" +
                    SectionTypeText[props.protocol.section] +
                    " " +
                    ProtocolSectionName[props.protocol.section] +
                    ")"}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Chip size={"small"} label={"$NAME"} />
                <Typography variant={"body2"}>
                  {"= Name der bearbeitenden Person (" +
                    me.prename +
                    " " +
                    me.surname +
                    ")"}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Chip size={"small"} label={"$SCHREIBEN"} />
                <Typography variant={"body2"}>
                  {"= Letzte Fristsetzung (" +
                    moment(
                      props.entries
                        .filter((entry) => !entry.hidden)[0]
                        .mails.filter((mail) => !mail.grace_period)[
                        props.entries.filter((entry) => !entry.hidden)[0].mails
                          .length - 1
                      ]?.created_at,
                    ).format("L") +
                    ")"}
                </Typography>
              </Stack>
            </Stack>

            <Divider />
            <Stack direction={"row"} spacing={1}>
              <Button onClick={() => setStep(step - 1)}>Zurück</Button>
              <Button
                disabled={!subject || !content}
                onClick={() => setStep(step + 1)}
              >
                Weiter
              </Button>
            </Stack>
          </Stack>
        );
      case 2:
        return (
          <Stack direction={"column"} spacing={1}>
            <Box px={1}>
              <Typography variant={"h6"}>Signatur Bauleiter</Typography>
            </Box>
            {signatureBauleiterImageUrl ? (
              <Stack direction={"column"}>
                {<img alt="signature" src={signatureBauleiterImageUrl} />}
                <Button
                  fullWidth
                  color={"secondary"}
                  onClick={() => setSignatureBauleiterImageUrl(null)}
                >
                  Zurücksetzen
                </Button>
              </Stack>
            ) : (
              <Card>
                <CardContent>
                  <Stack direction={"column"} spacing={1} p={1}>
                    {}
                    <SignatureCanvas
                      ref={(ref) => {
                        setSignatureBauleiterRef(ref);
                      }}
                      clearOnResize={false}
                      penColor="black"
                      canvasProps={{ className: styles.sigCanvas }}
                    />
                    <Stack direction={"row"} spacing={1}>
                      <Button
                        fullWidth
                        onClick={() =>
                          setSignatureBauleiterImageUrl(
                            signatureBauleiterRef
                              .getCanvas()
                              .toDataURL("image/png"),
                          )
                        }
                      >
                        Bestätigen
                      </Button>
                      <Button
                        fullWidth
                        color={"secondary"}
                        onClick={() => (
                          setSignatureBauleiterImageUrl(null),
                          signatureBauleiterRef.clear()
                        )}
                      >
                        Zurücksetzen
                      </Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            )}
            <Divider />
            <Stack direction={"row"} spacing={1}>
              <Button onClick={() => setStep(step - 1)}>Zurück</Button>
              <Button
                disabled={!signatureBauleiterImageUrl}
                onClick={() => setStep(step + 1)}
              >
                Weiter
              </Button>
            </Stack>
          </Stack>
        );
      case 3:
        return (
          <Stack direction={"column"} spacing={1}>
            <Box px={1}>
              <Typography variant={"h6"}>Signatur Kunde</Typography>
            </Box>
            {signatureKundeImageUrl ? (
              <Stack direction={"column"}>
                {<img alt="signature" src={signatureKundeImageUrl} />}
                <Button
                  fullWidth
                  color={"secondary"}
                  onClick={() => setSignatureKundeImageUrl(null)}
                >
                  Zurücksetzen
                </Button>
              </Stack>
            ) : (
              <Card>
                <CardContent>
                  <Stack direction={"column"} spacing={1} p={1}>
                    {}
                    <SignatureCanvas
                      ref={(ref) => {
                        setSignatureKundeRef(ref);
                      }}
                      clearOnResize={false}
                      penColor="black"
                      canvasProps={{ className: styles.sigCanvas }}
                    />
                    <Stack direction={"row"} spacing={1}>
                      <Button
                        fullWidth
                        onClick={() =>
                          setSignatureKundeImageUrl(
                            signatureKundeRef
                              .getCanvas()
                              .toDataURL("image/png"),
                          )
                        }
                      >
                        Bestätigen
                      </Button>
                      <Button
                        fullWidth
                        color={"secondary"}
                        onClick={() => (
                          signatureKundeRef.clear(),
                          setSignatureKundeImageUrl(null)
                        )}
                      >
                        Zurücksetzen
                      </Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            )}
            <Divider />
            <Stack direction={"row"} spacing={1}>
              <Button onClick={() => setStep(step - 1)}>Zurück</Button>
              <Button onClick={() => setStep(step + 1)}>Weiter</Button>
            </Stack>
          </Stack>
        );
      case 4:
        return (
          <Stack
            direction={"column"}
            spacing={1}
            divider={<Divider />}
            height={"100%"}
          >
            <Box width={"100%"}>
              <PDFViewer
                showToolbar={false}
                width={"100%"}
                height={"500px"}
                style={{ textDecoration: "none" }}
              >
                <PDF
                  title={`${ProtocolTypes[props.protocol.type]} ${SectionTypeText[props.protocol.section]} "${ProtocolSectionName[props.protocol.section]}"`}
                  subtitle={`Erstellt am ${moment(props.protocol?.created_at).format("DD.MM.YYYY HH:mm")} Uhr`}
                  withSignatures
                  bauleiterSignature={signatureBauleiterImageUrl}
                  kundenSignature={signatureKundeImageUrl}
                  withImages
                  items={
                    gracePeriod
                      ? updatedEntries
                      : props.entries.filter((entry) => !entry.hidden)
                  }
                />
              </PDFViewer>
            </Box>
            {((gracePeriod && updatedEntries.length === 0) ||
              (!gracePeriod &&
                props.entries.filter((entry) => !entry.hidden).length ===
                  0)) && (
              <Alert severity={"error"}>
                Leeres Protokoll. Bitte Fristen prüfen.
              </Alert>
            )}
            <Stack direction={"row"} spacing={1}>
              <Button onClick={() => setStep(step - 1)}>Zurück</Button>
              <Button
                onClick={() => setStep(step + 1)}
                disabled={
                  (gracePeriod && updatedEntries.length === 0) ||
                  (!gracePeriod &&
                    props.entries.filter((entry) => !entry.hidden).length === 0)
                }
              >
                Weiter
              </Button>
            </Stack>
          </Stack>
        );
      case 5:
        return (
          <Stack direction={"column"} spacing={1} divider={<Divider />}>
            <Typography variant={"h6"}>Versenden an</Typography>
            {sendArray.map((entry) => (
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Contact extend contactId={entry._id} />
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        onClick={(e) =>
                          e.target.checked
                            ? handleAddToArray(entry)
                            : handleRemoveFromArray(entry)
                        }
                        disabled={!entry.email}
                        defaultChecked={entry.email}
                      />
                    }
                    label="Senden"
                    labelPlacement="start"
                  />
                </FormControl>
              </Stack>
            ))}
            <Autocomplete
              id="contact"
              onChange={(e, value) => value && handleAddToArray(value)}
              options={contacts}
              fullWidth
              value={null}
              getOptionLabel={(option) =>
                option.gender + " " + option.prename + " " + option.surname
              }
              getOptionDisabled={(option) =>
                !option.email || sendArray.filter((c) => c._id === option._id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Kontakt hinzufügen"}
                  variant="filled"
                  size={"small"}
                />
              )}
            />
            <Stack direction={"row"} spacing={1}>
              <Button onClick={() => setStep(step - 1)}>Zurück</Button>
              <Button
                disabled={sendArray.length === 0}
                onClick={() => handleSendEmails()}
              >
                {"An " + sendArray.length + " Kontakt(e) senden"}
              </Button>
            </Stack>
          </Stack>
        );
      default:
        return <>Fehler</>;
    }
  };

  return (
    <Box>
      <Button size={"small"} color={"primary"} onClick={handleClick}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <IoMdSend />
          <Box>Versenden</Box>
        </Stack>
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack direction={"column"} spacing={2} divider={<Divider />}>
            {renderStepContent()}
          </Stack>
          {/*<Box sx={{width: {xs: '100%', md: '50%'}}}>
                <PDFViewer
                    showToolbar={false}
                    width={'100%'}
                    height={'600px'}
                    style={{textDecoration: 'none'}}>
                    <PDF withImages
                         items={filteredEntrys}/>
                </PDFViewer>
            </Box>*/}
        </Box>
      </Modal>
    </Box>
  );
};

export default SendEntries;
