import { gql } from "@apollo/client";

import { USER_ENTITY } from "../entities/userEntity";
import { FILE_ENTITY } from "../entities/fileEntity";
import { MAIL_ENTITY } from "../entities/mailEntity";

export const ON_USERS_SELF_CHANGED = gql`
  subscription {
    onUsersSelfChanged {
        ...UserEntity
        files {
          ...FileEntity
        }
        mails {
          ...MailEntity
        }
    }
  }
  ${USER_ENTITY},${FILE_ENTITY},${MAIL_ENTITY}
`;
