import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FullpageLoader from "../common/FullPageLoader";
import styles from "./customers.module.css";
import { VscAdd } from "react-icons/vsc";
import { useCustomers } from "../../apollo/hooks/useCustomers";
import { useContacts } from "../../apollo/hooks/useContacts";

const NewContact = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [ownCustomer, setOwnCustomer] = useState("");

  const [gender, setGender] = useState("HERR");
  const [prename, setPrename] = useState("");
  const [surname, setSurname] = useState("");
  const [manager, setManager] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [craft, setCraft] = useState([]);

  const {
    customers,
    executeCustomerQuery,
    addCustomer,
    loading: customersLoading,
    error: customersError,
  } = useCustomers();

  const {
    addContact,
    loading: contactsLoading,
    error: contactsError,
  } = useContacts();

  useEffect(() => {
    executeCustomerQuery();
  }, []);

  if (customersLoading || contactsLoading || !customers)
    return <FullpageLoader position={"relative"} />;
  if (customersError || contactsError)
    return <FullpageLoader error position={"relative"} />;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    setGender("HERR");
    setPrename("");
    setSurname("");
    setManager(false);
    setEmail("");
    setPhone("");
    setCraft([]);
    setAnchorEl(null);
    setSelectedCustomer([]);
    setOwnCustomer("");
  };

  const handleAddContact = () => {
    if (selectedCustomer?._id) {
      const contactPayload = {
        customer: selectedCustomer?._id,
        gender: gender,
        prename: prename,
        surname: surname,
        manager: manager,
        email: email,
        phone: phone,
        gewerk: craft,
      };
      addContact(contactPayload).then(() => {
        handleClear();
      });
    }

    if (ownCustomer && !selectedCustomer?._id) {
      const customerPayload = {
        name: ownCustomer,
      };
      addCustomer(customerPayload).then((res) => {
        if (res.data.createCustomer._id) {
          const contactPayload = {
            customer: res.data.createCustomer._id,
            gender: gender,
            prename: prename,
            surname: surname,
            manager: manager,
            email: email,
            phone: phone,
            gewerk: craft,
          };
          addContact(contactPayload).then(() => {
            handleClear();
          });
        }
      });
    }
  };

  const maskTemplate = () => {
    const handleSelectCustomer = (e, value) => {
      setSelectedCustomer(value);
    };
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            id="firma"
            freeSolo
            value={selectedCustomer ? selectedCustomer : []}
            onChange={(e, value) => handleSelectCustomer(e, value)}
            onInputChange={(e, value) => setOwnCustomer(value)}
            options={customers.map((customer) => customer)}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField {...params} label="Firma" variant="filled" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel>Anrede</InputLabel>
            <Select
              id="anrede"
              value={gender}
              label="Anrede"
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value={"HERR"}>Herr</MenuItem>
              <MenuItem value={"FRAU"}>Frau</MenuItem>
              <MenuItem value={"DIVERS"}>Divers</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            label={"Vorname"}
            variant={"filled"}
            value={prename}
            onChange={(e) => setPrename(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            label={"Nachname"}
            variant={"filled"}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={"Email"}
            variant={"filled"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={"Telefon"}
            variant={"filled"}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="gewerk"
            value={craft ? craft : []}
            onChange={(e, value) => setCraft(value)}
            freeSolo
            autoSelect
            options={[
              "Maler",
              "Bodenbelag",
              "Fliesen",
              "Eigengewerk",
              "Hausanschluss",
              "Lüftung",
              "Heizung",
              "Sanitär",
              "Klima",
              "Elektro",
            ]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Gewerke"
                variant="filled"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            spacing={1}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={manager}
                  onChange={(e) => setManager(e.target.checked)}
                />
              }
              label="Bauleiter"
            />
            <Button
              disabled={!prename || !surname}
              onClick={() => handleAddContact()}
            >
              Kontakt hinzufügen
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{ height: props.normalButton && "100%" }}
      className={!props.normalButton && styles.fabContainer}
    >
      {props.normalButton ? (
        <Button
          sx={{ height: "100%" }}
          onClick={handleClick}
          fullWidth
          size={"big"}
        >
          Neu
        </Button>
      ) : (
        <Fab onClick={handleClick} color={"info"} className={styles.fab}>
          <VscAdd />
        </Fab>
      )}
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Stack direction={"column"} p={1} spacing={1}>
          <Box p={1}>
            <Typography variant={"h6"}>Neuer Kontakt</Typography>
          </Box>
          <Box>{maskTemplate()}</Box>
        </Stack>
      </Dialog>
    </Box>
  );
};

export default NewContact;
