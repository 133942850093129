export const findPathById = (obj, id, path = []) => {
  if (obj._id === id) {
    //return path.join(" / ");
    return path;
  }

  for (const field of obj.fields || []) {
    const fieldPath = [...path, field];
    const result = findPathById(field, id, fieldPath);
    if (result) {
      return result;
    }
  }

  for (const building of obj.buildings || []) {
    const buildingPath = [...path, building];
    const result = findPathById(building, id, buildingPath);
    if (result) {
      return result;
    }

    for (const apartment of building.apartments || []) {
      if (apartment._id === id) {
        return [...buildingPath, apartment];
      }
    }
  }

  return null;
};

export function getPathById(obj, id, path = []) {
  for (const project of obj || []) {
    const projectPath = [...path, project];
    const result = findPathById(project, id, projectPath);
    if (result) {
      return result;
    }
  }
}
