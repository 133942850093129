import { gql } from "@apollo/client";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";
import { APARTMENT_ENTITY } from "../../entities/apartmentEntity";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";
import { FIELD_ENTITY } from "../../entities/fieldEntity";
import { PROJECT_ENTITY } from "../../entities/projectEntity";

export const GET_APARTMENT_BY_ID = gql`
  query getApartmentById($id: String!){
    getApartmentById(id: $id) {
        ...ApartmentEntity
        building {
          ...BuildingEntity
          field {
            ...FieldEntity
            project {
              ...ProjectEntity
            }
          }
        }
        protocols {
          ...ProtocolEntity
        }
    }
  }
  ${APARTMENT_ENTITY},${BUILDING_ENTITY},${PROTOCOL_ENTITY},${FIELD_ENTITY},${PROJECT_ENTITY}
`;
