import { useState } from "react";
import { Box, Button, Paper, Popover, Stack, Typography } from "@mui/material";

const NewProtocolForm = ({ project }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box textAlign={"center"}>
      <Button color={"primary"} variant={"contained"} onClick={handleClick}>
        Neues Protokoll
      </Button>
      <Popover
        autoFocus={true}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          <Stack direction={"column"} spacing={1} p={1}>
            {project.fields.map((field) => (
              <Stack direction={"column"} spacing={1}>
                <Typography>{field.name}</Typography>
                {field.buildings.map((building) => (
                  <Stack direction={"column"} spacing={1}>
                    <Typography>{building.name}</Typography>
                    {building.apartments.map((apartment) => (
                      <Typography>{apartment.name}</Typography>
                    ))}
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        </Paper>
      </Popover>
    </Box>
  );
};

const CreateProtocolDialog = ({ project }) => {
  return <NewProtocolForm project={project} />;
};

export default CreateProtocolDialog;
