import { gql } from "@apollo/client";
import { FIELD_ENTITY } from "../../entities/fieldEntity";

export const CREATE_FIELD = gql`
  mutation createField($input: FieldInput!) {
    createField(input: $input) {
      ...FieldEntity
    }
  }
  ${FIELD_ENTITY}
`;
