import { gql } from "@apollo/client";
import { FILE_ENTITY } from "../../entities/fileEntity";
import { USER_ENTITY } from "../../entities/userEntity";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const GET_ALL_FILES = gql`
  query getAllFiles {
    getAllFiles {
        ...FileEntity
        user {
          ...UserEntity
        }
        entry {
          ...EntryEntity
        }
    }
  }
  ${FILE_ENTITY},${USER_ENTITY},${ENTRY_ENTITY}
`;
