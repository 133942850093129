import { gql } from "@apollo/client";
import { CONTACT_ENTITY } from "../entities/contactEntity";
import { CUSTOMER_ENTITY } from "../entities/customerEntity";
import { ENTRY_ENTITY } from "../entities/entryEntity";

export const ON_CONTACTS_CHANGED = gql`
  subscription {
    onContactsChanged {
        ...ContactEntity
        customer {
          ...CustomerEntity
        }
        entrys {
          ...EntryEntity
        }
    }
  }
  ${CONTACT_ENTITY},${CUSTOMER_ENTITY},${ENTRY_ENTITY}
`;
