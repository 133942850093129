// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_loginWrapper__T3XSV {
  min-height: 100vh;
  width: 100%;
  background-color: #eaeaea;
}
`, "",{"version":3,"sources":["webpack://./src/views/common/Login/login.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".loginWrapper {\n  min-height: 100vh;\n  width: 100%;\n  background-color: #eaeaea;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginWrapper": `login_loginWrapper__T3XSV`
};
export default ___CSS_LOADER_EXPORT___;
