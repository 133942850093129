import { gql } from "@apollo/client";
import { PROJECT_ENTITY } from "../../entities/projectEntity";

export const UPDATE_PROJECT = gql`
  mutation updateProject($input: ProjectInput!, $id: String!) {
    updateProject(input: $input, id: $id) {
      ...ProjectEntity
    }
  }
  ${PROJECT_ENTITY}
`;
