import { Button, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FullpageLoader from "../common/FullPageLoader";
import { useProjects } from "../../apollo/hooks/useProjects";

const EditProject = (props) => {
  const router = useNavigate();
  const {
    updateProject,
    loading: projectsLoading,
    error: projectsError,
  } = useProjects();
  const [id, setId] = useState("");
  const [name, setName] = useState("");

  const setStates = async () => {
    setId(props.data?._id);
    setName(props.data?.name);
  };

  /* eslint-disable */
  useEffect(() => {
    if (props.data) setStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* eslint-enable */

  if (projectsLoading) return <FullpageLoader position={"relative"} />;
  if (projectsError) return <FullpageLoader error />;

  const handleEditProject = () => {
    let payload = {
      name: name,
    };
    updateProject(payload, id).then(() => {
      router(-1);
    });
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <TextField
        label={"Bezeichnung"}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Stack
        direction={"row"}
        spacing={1}
        display={"flex"}
        justifyContent={"end"}
      >
        <Button color={"secondary"} onClick={() => router(-1)}>
          Abbrechen
        </Button>
        <Button disabled={!name} onClick={() => handleEditProject()}>
          Speichern
        </Button>
      </Stack>
    </Stack>
  );
};

export default EditProject;
