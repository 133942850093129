import { gql } from "@apollo/client";

export const USER_ENTITY = gql`
  fragment UserEntity on UserEntity {
    _id
    username
    role
    prename
    surname
    last_seen
    created_at
    updated_at
  }
`;
