import { gql } from "@apollo/client";

export const FILE_ENTITY = gql`
  fragment FileEntity on FileEntity {
    _id
    filename
    mimetype
    created_at
    updated_at
  }
`;
