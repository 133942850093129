// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileupload_fileUploadWrapper__M21QO {
  background-color: #f0f2f5;
  border: 1px solid #caccd7;
  border-style: dashed;
  border-radius: 10px;
}
.fileupload_fileWrapper__DsWZd {
  border: 1px solid #caccd7;
  background-color: #f0f2f5;
  border-radius: 10px;
}
.fileupload_toolarge__MJTbd {
  border: 1px solid #a51c1c;
  background-color: #ffefef;
  color: #a51c1c;
}
`, "",{"version":3,"sources":["webpack://./src/views/FileUpload/fileupload.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".fileUploadWrapper {\n  background-color: #f0f2f5;\n  border: 1px solid #caccd7;\n  border-style: dashed;\n  border-radius: 10px;\n}\n.fileWrapper {\n  border: 1px solid #caccd7;\n  background-color: #f0f2f5;\n  border-radius: 10px;\n}\n.toolarge {\n  border: 1px solid #a51c1c;\n  background-color: #ffefef;\n  color: #a51c1c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadWrapper": `fileupload_fileUploadWrapper__M21QO`,
	"fileWrapper": `fileupload_fileWrapper__DsWZd`,
	"toolarge": `fileupload_toolarge__MJTbd`
};
export default ___CSS_LOADER_EXPORT___;
