import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IoAddSharp } from "react-icons/io5";
import styles from "./projects.module.css";
import Buildings from "./Buildings";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateProtocol from "../Protocols/CreateProtocol";
import { FieldName } from "../../types";
import { useBuildings } from "../../apollo/hooks/useBuildings";
import { useFields } from "../../apollo/hooks/useFields";

const Fields = (props) => {
  const router = useNavigate();

  const NewBuildingForm = (props) => {
    const { addBuilding } = useBuildings();
    const [buildingName, setBuildingName] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleKeyPress = (e) => {
      if (e.keyCode === 13 && buildingName) {
        handleAddBuilding();
      }
    };
    const handleAddBuilding = () => {
      let payload = {
        name: buildingName,
        field: props.field._id,
      };
      addBuilding(payload).then(() => {
        setBuildingName("");
      });
    };
    return (
      <Box
        p={0}
        textAlign={"center"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "100%" }}
      >
        <Button color={"primary"} onClick={handleClick}>
          + Gebäude
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <Stack direction={"row"} spacing={1} p={0.5}>
              <TextField
                variant={"filled"}
                onKeyDown={handleKeyPress}
                autoFocus
                size={"small"}
                value={buildingName}
                onChange={(e) => setBuildingName(e.target.value)}
                label={"Gebäude Bezeichnung"}
              />
              <Box>
                <Button
                  disabled={!buildingName}
                  sx={{ height: "100%" }}
                  size={"small"}
                  onClick={() => handleAddBuilding()}
                >
                  <IoAddSharp />
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  const EditFieldForm = (props) => {
    const { removeField } = useFields();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleRemoveField = () => {
      if (
        window.confirm(`Möchtest du das Baufeld "${props.field.name}" löschen?`)
      ) {
        removeField(props.field._id);
      }
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Fab size={"small"} color={"primary"} onClick={handleClick}>
          <BiDotsVerticalRounded />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            disableRipple
            onClick={() => router("/protocols/field/" + props.field._id)}
          >
            <Typography>Protokolle</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <CreateProtocol
            section={"field"}
            id={props.field._id}
            project={props.project}
            data={props.field}
            menuItem
          />
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disableRipple
            onClick={() => router("/edit/field/" + props.field._id)}
          >
            <Typography>Bearbeiten</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem disableRipple onClick={handleRemoveField}>
            <Typography color={"secondary"}>
              {props.field.name + " Löschen"}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return props.fields.map((field, index) => (
    <Grid key={index} item xs={12} md={12}>
      <Card sx={{ height: "100%" }}>
        <CardContent className={styles.fieldWrapper} sx={{ height: "100%" }}>
          <Stack direction={"column"} spacing={1} p={1}>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"column"}>
                <Typography variant={"h7"}>{field.name}</Typography>
                <Typography
                  variant={"body2"}
                >{`${FieldName[props.project.type]} - ${props.protocols.filter((protocol) => protocol.field?._id === field._id).length} Protokoll(e)`}</Typography>
              </Stack>
              <EditFieldForm field={field} project={props.project} />
            </Stack>
            <Box>
              <Grid container spacing={1}>
                <Buildings
                  buildings={field.buildings}
                  protocols={props.protocols}
                  project={props.project}
                />
                <Grid item xs={12} md={12}>
                  <NewBuildingForm field={field} />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  ));
};

export default Fields;
