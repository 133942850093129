import { Button, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FullpageLoader from "../common/FullPageLoader";
import { useBuildings } from "../../apollo/hooks/useBuildings";

const EditBuilding = (props) => {
  const router = useNavigate();
  const {
    updateBuilding,
    loading: buildingsLoading,
    error: buildingsError,
  } = useBuildings();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [field, setField] = useState("");

  const setStates = async () => {
    setId(props.data._id);
    setName(props.data.name);
    setField(props.data.field._id);
  };

  useEffect(() => {
    setStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (buildingsLoading) return <FullpageLoader position={"relative"} />;
  if (buildingsError) return <FullpageLoader error />;

  const handleEditBuilding = () => {
    let payload = {
      field: field,
      name: name,
    };
    updateBuilding(payload, id).then(() => {
      router(-1);
    });
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <TextField
        label={"Bezeichnung"}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Stack
        direction={"row"}
        spacing={1}
        display={"flex"}
        justifyContent={"end"}
      >
        <Button color={"secondary"} onClick={() => router(-1)}>
          Abbrechen
        </Button>
        <Button disabled={!name} onClick={() => handleEditBuilding()}>
          Speichern
        </Button>
      </Stack>
    </Stack>
  );
};

export default EditBuilding;
