import { gql } from "@apollo/client";

export const PROJECT_ENTITY = gql`
  fragment ProjectEntity on ProjectEntity {
    _id
    name
    type
    created_at
    updated_at
  }
`;
