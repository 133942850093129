import { gql } from "@apollo/client";

export const CONTACT_ENTITY = gql`
  fragment ContactEntity on ContactEntity {
    _id
    gender
    prename
    surname
    manager
    email
    phone
    gewerk
    created_at
    updated_at
  }
`;
