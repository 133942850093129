import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_MAILS } from "../queries/Mail/getAllMails";
import { SEND_MAIL } from "../mutations/Mail/sendMail";
import { ON_MAILS_CHANGED } from "../subscriptions/onMailsChanged";
import { GET_MAIL_BY_ID } from "../queries/Mail/getMailById";

const query = GET_ALL_MAILS;
const sendMutation = SEND_MAIL;
const getByIdMutation = GET_MAIL_BY_ID;
const subscription = ON_MAILS_CHANGED;

const entityName = "mail";

export const useMails = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [send, { loading: sendMutationLoading, error: sendMutationError }] =
    useMutation(sendMutation, {
      onCompleted: () => {
        console.log(entityName + " sent!");
      },
      onError: (error) => {
        console.error("[" + entityName + "] Mutation error:", error);
      },
    });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onMailsChanged);
      queryRefetch();
    },
  });

  const executeMailQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllMails);
    }
  }, [queryData]);

  return {
    loading: queryLoading || sendMutationLoading || getByIdMutationLoading,
    error: queryError || sendMutationError || getByIdMutationError,
    mails: data,
    executeMailQuery,
    sendMail: (file, input) =>
      send({
        variables: {
          file: file,
          input: input,
        },
      }),
    getMailById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
