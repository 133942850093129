import { gql } from "@apollo/client";

export const BUILDING_ENTITY = gql`
  fragment BuildingEntity on BuildingEntity {
    _id
    name
    created_at
    updated_at
  }
`;
