import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IoAddSharp } from "react-icons/io5";
import styles from "./projects.module.css";
import Apartments from "./Apartments";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateProtocol from "../Protocols/CreateProtocol";
import { useApartments } from "../../apollo/hooks/useApartments";
import { useBuildings } from "../../apollo/hooks/useBuildings";

const Buildings = (props) => {
  const router = useNavigate();

  const NewApartmentForm = (props) => {
    const { addApartment } = useApartments();
    const [apartmentName, setApartmentName] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleKeyPress = (e) => {
      if (e.keyCode === 13 && apartmentName) {
        handleAddApartment();
      }
    };
    const handleAddApartment = () => {
      let payload = {
        name: apartmentName,
        building: props.building._id,
      };
      addApartment(payload).then(() => {
        setApartmentName("");
      });
    };
    return (
      <Box
        p={0}
        textAlign={"center"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "100%" }}
      >
        <Button color={"primary"} onClick={handleClick}>
          + Wohneinheit
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <Stack direction={"row"} spacing={1} p={0.5}>
              <TextField
                variant={"filled"}
                onKeyDown={handleKeyPress}
                autoFocus
                size={"small"}
                value={apartmentName}
                onChange={(e) => setApartmentName(e.target.value)}
                label={"Wohneinheit Bezeichnung"}
              />
              <Box>
                <Button
                  disabled={!apartmentName}
                  sx={{ height: "100%" }}
                  size={"small"}
                  onClick={() => handleAddApartment()}
                >
                  <IoAddSharp />
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  const EditBuildingForm = (props) => {
    const { removeBuilding } = useBuildings();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleRemoveBuilding = () => {
      if (
        window.confirm(
          `Möchtest du das Gebäude "${props.building.name}" löschen?`,
        )
      ) {
        removeBuilding(props.building._id);
      }
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Fab size={"small"} color={"primary"} onClick={handleClick}>
          <BiDotsVerticalRounded />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            disableRipple
            onClick={() => router("/protocols/building/" + props.building._id)}
          >
            <Typography>Protokolle</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <CreateProtocol
            section={"building"}
            id={props.building._id}
            project={props.project}
            data={props.building}
            menuItem
          />
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disableRipple
            onClick={() => router("/edit/building/" + props.building._id)}
          >
            <Typography>Bearbeiten</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem disableRipple onClick={handleRemoveBuilding}>
            <Typography color={"secondary"}>
              {props.building.name + " Löschen"}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return props.buildings.map((building, index) => (
    <Grid key={index} item xs={12} md={6}>
      <Card sx={{ height: "100%" }}>
        <CardContent className={styles.buildingWrapper} sx={{ height: "100%" }}>
          <Stack direction={"column"} spacing={1} p={1} sx={{ height: "100%" }}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"column"}>
                <Typography variant={"h7"}>{building.name}</Typography>
                <Typography
                  variant={"body2"}
                >{`Gebäude - ${props.protocols.filter((protocol) => protocol.building?._id === building._id).length} Protokoll(e)`}</Typography>
              </Stack>
              <EditBuildingForm building={building} project={props.project} />
            </Stack>
            <Box>
              <Grid container spacing={1}>
                <Apartments
                  apartments={building.apartments}
                  protocols={props.protocols}
                  project={props.project}
                />
                <Grid item xs={12} md={12} sx={{ height: "100%" }}>
                  <NewApartmentForm building={building} />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  ));
};

export default Buildings;
