import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FullPageLoader from "../common/FullPageLoader";
import { useGlobalStore } from "../../store";
import { useFiles } from "../../apollo/hooks/useFiles";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useProtocols } from "../../apollo/hooks/useProtocols";

const ProtocolDetails = ({ protocol }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const selectedFile = useGlobalStore((state) => state.selectedFile);
  const clearSelectedFile = useGlobalStore((state) => state.clearSelectedFile);

  const [zaehlerStrom, setZahlerStrom] = useState("");
  const [zaehlerHeizung, setZahlerHeizung] = useState("");
  const [zaehlerWasser, setZahlerWasser] = useState("");
  const [keysBriefkasten, setKeysBriefkasten] = useState("");
  const [keysHaus, setKeysHaus] = useState("");
  const [keyNumber, setKeyNumber] = useState("");

  const { uploadFile, loading: filesLoading, error: filesError } = useFiles();
  const {
    me,
    executeUserSelfQuery,
    loading: usersLoading,
    error: usersError,
  } = useUsers();
  const {
    updateProtocolZaehler,
    loading: protocolZaehlerLoading,
    error: protocolZahlerError,
  } = useProtocols();

  useEffect(() => {
    executeUserSelfQuery();
  }, []);

  useEffect(() => {
    setZahlerStrom(protocol?.zaehler_strom);
    setZahlerHeizung(protocol?.zaehler_heizung);
    setZahlerWasser(protocol?.zaehler_wasser);
    setKeysBriefkasten(protocol?.keys_briefkasten);
    setKeysHaus(protocol?.keys_haus);
    setKeyNumber(protocol?.keynumber);
  }, [protocol]);

  if (filesLoading || usersLoading || !me || protocolZaehlerLoading)
    return <FullPageLoader position={"relative"} />;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateProtocol = () => {
    let payload = {
      zaehler_strom: zaehlerStrom,
      zaehler_wasser: zaehlerWasser,
      zaehler_heizung: zaehlerHeizung,
      keys_briefkasten: keysBriefkasten,
      keys_haus: keysHaus,
      keynumber: keyNumber,
    };
    updateProtocolZaehler(payload, protocol._id).then(() => {
      setZahlerStrom("");
      setZahlerHeizung("");
      setZahlerWasser("");
      setKeysBriefkasten(0);
      setKeysHaus(0);
      setKeyNumber("");
      handleClose();
    });
  };

  const inputMask = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={zaehlerStrom}
            onChange={(e) => setZahlerStrom(e.target.value)}
            label={"Zählerstand Strom in kWh"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={zaehlerHeizung}
            onChange={(e) => setZahlerHeizung(e.target.value)}
            label={"Zählerstand Heizung in kWh"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={zaehlerWasser}
            onChange={(e) => setZahlerWasser(e.target.value)}
            label={"Zählerstand Wasser in m³"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={keysBriefkasten}
            label={"Schlüssel Briefkasten"}
            onChange={(e) => setKeysBriefkasten(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={keysHaus}
            onChange={(e) => setKeysHaus(e.target.value)}
            label={"Schlüssel Haus"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={keyNumber}
            onChange={(e) => setKeyNumber(e.target.value)}
            label={"Schlüsselnummer"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display={"flex"} justifyContent={"end"}>
            <Button onClick={() => handleUpdateProtocol()}>Speichern</Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={1}>
      <Button onClick={handleClick}>Zählerstände erfassen</Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Stack direction={"column"} p={1} spacing={1}>
          <Box p={1}>
            <Typography variant={"h6"}>Protokoll Details</Typography>
          </Box>
          <Box>{inputMask()}</Box>
        </Stack>
      </Dialog>
    </Box>
  );
};

export default ProtocolDetails;
