import { gql } from "@apollo/client";

export const CUSTOMER_ENTITY = gql`
  fragment CustomerEntity on CustomerEntity {
    _id
    name
    street
    number
    plz
    city
    created_at
    updated_at
  }
`;
