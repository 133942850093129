import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_USERS } from "../queries/User/getAllUsers";
import { CREATE_USER } from "../mutations/User/createUser";
import { UPDATE_USER } from "../mutations/User/updateUser";
import { REMOVE_USER } from "../mutations/User/removeUser";
import { ON_USERS_CHANGED } from "../subscriptions/onUsersChanged";
import { GET_USER_BY_ID } from "../queries/User/getUserById";
import { ME } from "../queries/User/me";
import { ON_USERS_SELF_CHANGED } from "../subscriptions/onUsersSelfChanged";
import { GET_USER_ROLES } from "../queries/User/getUserRoles";

const query = GET_ALL_USERS;
const querySelf = ME;
const queryRoles = GET_USER_ROLES;
const createMutation = CREATE_USER;
const updateMutation = UPDATE_USER;
const removeMutation = REMOVE_USER;
const getByIdMutation = GET_USER_BY_ID;
const subscription = ON_USERS_CHANGED;
const subscriptionSelf = ON_USERS_SELF_CHANGED;

const entityName = "user";

export const useUsers = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [skipSelfQuery, setSkipSelfQuery] = useState(true);
  const [skipRolesQuery, setSkipRolesQuery] = useState(true);
  const [data, setData] = useState(null);
  const [selfData, setSelfData] = useState(null);
  const [rolesData, setRolesData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const {
    data: querySelfData,
    loading: querySelfLoading,
    error: querySelfError,
    refetch: querySelfRefetch,
  } = useQuery(querySelf, {
    skip: skipSelfQuery,
  });

  const {
    data: queryRolesData,
    loading: queryRolesLoading,
    error: queryRolesError,
  } = useQuery(queryRoles, {
    skip: skipRolesQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onUsersChanged);
      queryRefetch();
    },
  });

  useSubscription(subscriptionSelf, {
    onSubscriptionData: ({ subscriptionData }) => {
      setSelfData(subscriptionData.data.onUsersSelfChanged);
      querySelfRefetch();
    },
  });

  const executeUserQuery = () => {
    setSkipQuery(false);
  };

  const executeUserSelfQuery = () => {
    setSkipSelfQuery(false);
  };

  const executeUserRolesQuery = () => {
    setSkipRolesQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllUsers);
    }
  }, [queryData]);

  useEffect(() => {
    if (querySelfData) {
      setSelfData(querySelfData.me);
    }
  }, [querySelfData]);

  useEffect(() => {
    if (queryRolesData) {
      setRolesData(queryRolesData.getUserRoles);
    }
  }, [queryRolesData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      getByIdMutationLoading ||
      querySelfLoading ||
      queryRolesLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError ||
      getByIdMutationError ||
      querySelfError ||
      queryRolesError,
    users: data,
    me: selfData,
    userRoles: rolesData,
    executeUserQuery,
    executeUserSelfQuery,
    executeUserRolesQuery,
    addUser: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateUser: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeUser: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getUserById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
