import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_PROTOCOLS } from "../queries/Protocol/getAllProtocols";
import { CREATE_PROTOCOL } from "../mutations/Protocol/createProtocol";
import { UPDATE_PROTOCOL } from "../mutations/Protocol/updateProtocol";
import { REMOVE_PROTOCOL } from "../mutations/Protocol/removeProtocol";
import { ON_PROTOCOLS_CHANGED } from "../subscriptions/onProtocolsChanged";
import { DISABLE_PROTOCOL } from "../mutations/Protocol/disableProtocol";
import { GET_PROTOCOL_BY_ID } from "../queries/Protocol/getProtocolById";
import { UPDATE_PROTOCOL_ZAEHLER } from "../mutations/Protocol/updateProtocolZaehler";

const query = GET_ALL_PROTOCOLS;
const createMutation = CREATE_PROTOCOL;
const updateMutation = UPDATE_PROTOCOL;
const updateZaehlerMutation = UPDATE_PROTOCOL_ZAEHLER;
const disableMutation = DISABLE_PROTOCOL;
const removeMutation = REMOVE_PROTOCOL;
const getByIdMutation = GET_PROTOCOL_BY_ID;
const subscription = ON_PROTOCOLS_CHANGED;

const entityName = "protocol";

export const useProtocols = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    updateZaehler,
    {
      loading: updateZaehlerMutationLoading,
      error: updateZaehlerMutationError,
    },
  ] = useMutation(updateZaehlerMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    disable,
    { loading: disableMutationLoading, error: disableMutationError },
  ] = useMutation(disableMutation, {
    onCompleted: () => {
      console.log(entityName + " disable state changed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onProtocolsChanged);
      queryRefetch();
    },
  });

  const executeProtocolQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllProtocols);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      updateZaehlerMutationLoading ||
      removeMutationLoading ||
      disableMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      updateZaehlerMutationError ||
      removeMutationError ||
      disableMutationError ||
      getByIdMutationError,
    protocols: data,
    executeProtocolQuery,
    addProtocol: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateProtocol: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    updateProtocolZaehler: (input, id) =>
      updateZaehler({
        variables: {
          input: input,
          id: id,
        },
      }),
    disableProtocol: (input, id) =>
      disable({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeProtocol: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getProtocolById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
