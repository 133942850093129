import { gql } from "@apollo/client";
import { CONTACT_ENTITY } from "../../entities/contactEntity";

export const CREATE_CONTACT = gql`
  mutation createContact($input: ContactInput!) {
    createContact(input: $input) {
      ...ContactEntity
    }
  }
  ${CONTACT_ENTITY}
`;
