import { gql } from "@apollo/client";
import { PROJECT_ENTITY } from "../../entities/projectEntity";

export const CREATE_PROJECT = gql`
  mutation createProject($input: ProjectInput!) {
    createProject(input: $input) {
      ...ProjectEntity
    }
  }
  ${PROJECT_ENTITY}
`;
