import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_FIELDS } from "../queries/Field/getAllFields";
import { CREATE_FIELD } from "../mutations/Field/createField";
import { UPDATE_FIELD } from "../mutations/Field/updateField";
import { REMOVE_FIELD } from "../mutations/Field/removeField";
import { ON_FIELDS_CHANGED } from "../subscriptions/onFieldsChanged";
import { GET_FIELD_BY_ID } from "../queries/Field/getFieldById";

const query = GET_ALL_FIELDS;
const createMutation = CREATE_FIELD;
const updateMutation = UPDATE_FIELD;
const removeMutation = REMOVE_FIELD;
const getByIdMutation = GET_FIELD_BY_ID;
const subscription = ON_FIELDS_CHANGED;

const entityName = "field";

export const useFields = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onFieldsChanged);
      queryRefetch();
    },
  });

  const executeFieldQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllFields);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError ||
      getByIdMutationError,
    fields: data,
    executeFieldQuery,
    addField: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateField: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeField: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getFieldById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
