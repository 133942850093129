import { gql } from "@apollo/client";
import { PROJECT_ENTITY } from "../../entities/projectEntity";
import { FIELD_ENTITY } from "../../entities/fieldEntity";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";
import { APARTMENT_ENTITY } from "../../entities/apartmentEntity";

export const GET_ALL_PROJECTS = gql`
  query getAllProjects {
    getAllProjects {
        ...ProjectEntity
        fields {
          ...FieldEntity
          protocols {
            ...ProtocolEntity
          }
          buildings {
            ...BuildingEntity
            protocols {
              ...ProtocolEntity
            }
            apartments {
              ...ApartmentEntity
              protocols {
                ...ProtocolEntity
              }
            }
          }
        }
        protocols {
          ...ProtocolEntity
        }
    }
  }
  ${PROJECT_ENTITY},${FIELD_ENTITY},${PROTOCOL_ENTITY},${BUILDING_ENTITY},${APARTMENT_ENTITY}
`;
