import { gql } from "@apollo/client";
import { USER_ENTITY } from "../../entities/userEntity";

export const ME = gql`
  query me {
    me {
      ...UserEntity
    }
  }
  ${USER_ENTITY}
`;
