import { gql } from "@apollo/client";

import { USER_ENTITY } from "../../entities/userEntity";
import { MAIL_ENTITY } from "../../entities/mailEntity";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const GET_ALL_MAILS = gql`
  query getAllMails {
    getAllMails {
        ...MailEntity
        user {
          ...UserEntity
        }
        entrys {
          ...EntryEntity
        }
    }
  }
  ${MAIL_ENTITY},${USER_ENTITY},${ENTRY_ENTITY}
`;
