import { gql } from "@apollo/client";
import { USER_ENTITY } from "../../entities/userEntity";

export const UPDATE_USER = gql`
  mutation updateUser($input: UserInput!, $id: String!) {
    updateUser(input: $input, id: $id) {
      ...UserEntity
    }
  }
  ${USER_ENTITY}
`;
