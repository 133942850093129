import { gql } from "@apollo/client";
import { APARTMENT_ENTITY } from "../../entities/apartmentEntity";

export const UPDATE_APARTMENT = gql`
  mutation updateApartment($input: ApartmentInput!, $id: String!) {
    updateApartment(input: $input, id: $id) {
      ...ApartmentEntity
    }
  }
  ${APARTMENT_ENTITY}
`;
