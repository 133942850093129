import { gql } from "@apollo/client";
import { USER_ENTITY } from "../../entities/userEntity";
import { FILE_ENTITY } from "../../entities/fileEntity";
import { MAIL_ENTITY } from "../../entities/mailEntity";

export const GET_USER_BY_ID = gql`
  query getUserById($id: String!){
    getUserById(id: $id) {
        ...UserEntity
        files {
          ...FileEntity
        }
        mails {
          ...MailEntity
        }
    }
  }
  ${USER_ENTITY},${FILE_ENTITY},${MAIL_ENTITY}
`;
