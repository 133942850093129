import FullPageLoader from "../common/FullPageLoader";
import {
  Alert,
  Box,
  Button,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoAddSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../common/CustomPagination";
import { ProjectName } from "../../types";
import { useProjects } from "../../apollo/hooks/useProjects";
import CreateProtocolDialog from "../Protocols/CreateProtocolDialog";

const Root = (props) => {
  const router = useNavigate();

  const {
    projects,
    executeProjectQuery,
    addProject,
    error: projectError,
    loading: projectLoading,
  } = useProjects();
  const [paginationItems, setPaginationItems] = useState([]);

  useEffect(() => {
    executeProjectQuery();
  }, []);

  if (projectError) return <FullPageLoader error />;
  if (projectLoading || !projects)
    return <FullPageLoader position={"relative"} />;

  const NewProjectForm = () => {
    const ProjectCreateTypes = {
      project: "BAUVORHABEN",
      object: "OBJEKT",
    };
    const [projectName, setProjectName] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleKeyPress = (e) => {
      if (e.keyCode === 13 && projectName) {
        handleAddProject();
      }
    };
    const handleAddProject = () => {
      let payload = {
        name: projectName,
        type: ProjectCreateTypes[props.type],
      };
      addProject(payload).then((res) => {
        setProjectName("");
        router("/projects/" + res.data.createProject._id);
      });
    };
    return (
      <Box textAlign={"center"}>
        <Button color={"primary"} onClick={handleClick}>
          + {ProjectName[ProjectCreateTypes[props.type]]}
        </Button>
        <Popover
          autoFocus={true}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <Stack direction={"row"} spacing={1} p={0.5}>
              <TextField
                variant={"filled"}
                onKeyDown={handleKeyPress}
                autoFocus
                size={"small"}
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                label={
                  ProjectName[ProjectCreateTypes[props.type]] + " Bezeichnung"
                }
              />
              <Box>
                <Button
                  disabled={!projectName}
                  sx={{ height: "100%" }}
                  size={"small"}
                  onClick={() => handleAddProject()}
                >
                  <IoAddSharp />
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  const ProjectTypes = {
    BAUVORHABEN: "project",
    OBJEKT: "object",
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {props.select && (
        <Alert severity={"info"}>
          Wählen Sie das Bauvorhaben aus, für das Sie Ihre Aufgaben sehen
          möchten.
        </Alert>
      )}
      {paginationItems
        .filter((project) => ProjectTypes[project.type] === props.type)
        .map((project, index) => (
          <Paper key={index}>
            <Stack direction={"column"}>
              <Stack
                direction={"row"}
                spacing={1}
                p={2}
                justifyContent={"space-between"}
              >
                <Stack direction={"column"}>
                  <Typography variant={"h7"}>{project.name}</Typography>
                  <Typography variant={"body2"}>
                    {ProjectName[project.type]}
                  </Typography>
                </Stack>
                {!props.select ? (
                  <Stack direction={"column"} spacing={1} alignItems={"end"}>
                    <Stack direction={"row"} spacing={1}>
                      {/*
                      <Button
                        onClick={() =>
                          router("/protocols/project/" + project._id + "/mails")
                        }
                      >
                        Fristen / Nachfristen
                      </Button>
                      */}
                      <Button
                        onClick={() =>
                          router("/protocols/project/" + project._id)
                        }
                      >
                        Protokolle
                      </Button>
                      <Button
                        onClick={() => router("/projects/" + project._id)}
                      >
                        Struktur
                      </Button>
                    </Stack>
                    {/*<CreateProtocolDialog project={project} />*/}
                  </Stack>
                ) : (
                  <Button
                    onClick={() =>
                      router("/protocols/project/" + project._id + "/tasks")
                    }
                  >
                    Aufgaben ansehen
                  </Button>
                )}
              </Stack>
            </Stack>
          </Paper>
        ))}
      {!props.select && (
        <Paper>
          <Box p={1} textAlign={"center"}>
            <NewProjectForm />
          </Box>
        </Paper>
      )}
      <CustomPagination
        items={projects}
        onPageChange={(data) => setPaginationItems(data)}
      />
    </Stack>
  );
};

export default Root;
