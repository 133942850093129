import { gql } from "@apollo/client";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";

export const UPDATE_PROTOCOL = gql`
  mutation updateProtocol($input: ProtocolInput!, $id: String!) {
    updateProtocol(input: $input, id: $id) {
      ...ProtocolEntity
    }
  }
  ${PROTOCOL_ENTITY}
`;
