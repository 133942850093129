import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";

const BackButton = () => {
  const router = useNavigate();

  return (
    <Box>
      <Button onClick={() => router(-1)}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <IoChevronBack />
          <Box>Zurück</Box>
        </Stack>
      </Button>
    </Box>
  );
};

export default BackButton;
