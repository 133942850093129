import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import styles from "./fullpageloader.module.css";
import { MdError } from "react-icons/md";

const FullpageLoader = (props) => (
  <Box
    p={2}
    className={styles.loadingWrapper}
    style={{ position: props.position ? props.position : "absolute" }}
    display={"flex"}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <Stack direction={"column"} spacing={1} textAlign={"center"}>
      <Box>
        {props.error ? (
          <Typography
            color={"secondary"}
            variant={"h3"}
            style={{ lineHeight: "1rem" }}
          >
            <MdError />
          </Typography>
        ) : (
          <CircularProgress />
        )}
      </Box>
      {props.error ? (
        <Typography variant={"body2"}>Fehler</Typography>
      ) : (
        <Typography variant={"body2"}>Lade Daten...</Typography>
      )}
    </Stack>
  </Box>
);

export default FullpageLoader;
