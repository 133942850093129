import { gql } from "@apollo/client";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const UPDATE_ENTRY = gql`
  mutation updateEntry($input: EntryInput!, $id: String!) {
    updateEntry(input: $input, id: $id) {
      ...EntryEntity
    }
  }
  ${ENTRY_ENTITY}
`;
