export const ProjectName = {
  BAUVORHABEN: "Bauvorhaben",
  OBJEKT: "Objekt",
};
export const FieldName = {
  BAUVORHABEN: "Baufeld",
  OBJEKT: "Bereich",
};

export const SectionTypeText = {
  PROJECT: "Projekt",
  FIELD: "Bereich",
  BUILDING: "Gebäude",
  APARTMENT: "Wohneinheit",
};

export const ProtocolTypes = {
  BEGEHUNG: "Begehungsprotokoll",
  BESPRECHUNG: "Besprechungsprotokoll",
  FESTLEGUNG: "Festlegungsprotokoll",
  MAENGEL: "Mängelprotokoll",
  TAGESBERICHT: "Bautagebericht",
  UEBERGABE: "Übergabeprotokoll",
};
