import { gql } from "@apollo/client";
import { PROJECT_ENTITY } from "../../entities/projectEntity";
import { FIELD_ENTITY } from "../../entities/fieldEntity";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";
import { USER_ENTITY } from "../../entities/userEntity";
import { APARTMENT_ENTITY } from "../../entities/apartmentEntity";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const GET_ALL_PROTOCOLS = gql`
  query getAllProtocols {
    getAllProtocols {
        ...ProtocolEntity
        user {
            ...UserEntity
        }
        project {
            ...ProjectEntity
        }
        field {
            ...FieldEntity
        }
        building {
            ...BuildingEntity
        }
        apartment {
            ...ApartmentEntity
        }
        entrys {
            ...EntryEntity
        }
    }
  }
  ${PROTOCOL_ENTITY},${USER_ENTITY},${PROJECT_ENTITY},${FIELD_ENTITY},${BUILDING_ENTITY},${APARTMENT_ENTITY},${ENTRY_ENTITY}
`;
