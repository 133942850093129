import React, { useEffect } from "react";

import Login from "../views/common/Login/Login";
import FullPageLoader from "../views/common/FullPageLoader";
import { useUsers } from "../apollo/hooks/useUsers";

const AuthLoader = (props) => {
  const { me, executeUserSelfQuery, loading, error } = useUsers();
  useEffect(() => {
    executeUserSelfQuery();
  }, []);
  if (loading) return <FullPageLoader position={"absolute"} />;
  if (error || props.loginRequired) return <Login />;
  return props.children;
};

export default AuthLoader;
