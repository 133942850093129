import { gql } from "@apollo/client";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";

export const UPDATE_PROTOCOL_ZAEHLER = gql`
  mutation updateProtocolZaehler($input: ProtocolZaehlerInput!, $id: String!) {
    updateProtocolZaehler(input: $input, id: $id) {
      ...ProtocolEntity
    }
  }
  ${PROTOCOL_ENTITY}
`;
