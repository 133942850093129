import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FullpageLoader from "../common/FullPageLoader";
import NewContact from "./NewContact";
import { useCustomers } from "../../apollo/hooks/useCustomers";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useContacts } from "../../apollo/hooks/useContacts";

const Customers = () => {
  const [editname, setEditName] = useState("");
  const [editstreet, setEditStreet] = useState("");
  const [editnumber, setEditNumber] = useState("");
  const [editplz, setEditPlz] = useState("");
  const [editcity, setEditCity] = useState("");

  const [editContactGender, setEditContactGender] = useState("");
  const [editContactPrename, setEditContactPrename] = useState("");
  const [editContactSurname, setEditContactSurname] = useState("");
  const [editContactManager, setEditContactManager] = useState(false);
  const [editContactEmail, setEditContactEmail] = useState("");
  const [editContactPhone, setEditContactPhone] = useState("");
  const [editContactCraft, setEditContactCraft] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [editIndex, setEditIndex] = useState(null);
  const [editContactIndex, setEditContactIndex] = useState(null);

  const {
    customers,
    executeCustomerQuery,
    removeCustomer,
    updateCustomer,
    loading: customersLoading,
    error: customersError,
  } = useCustomers();

  const {
    updateContact,
    removeContact,
    loading: contactsLoading,
    error: contactsError,
  } = useContacts();

  const {
    me,
    executeUserSelfQuery,
    loading: usersLoading,
    error: usersError,
  } = useUsers();

  useEffect(() => {
    executeCustomerQuery();
    executeUserSelfQuery();
  }, []);

  if (customersLoading || usersLoading || contactsLoading || !customers || !me)
    return <FullpageLoader position={"relative"} />;
  if (customersError || usersError || contactsError)
    return <FullpageLoader error position={"relative"} />;

  const handleSelectCustomer = (event, index) => {
    if (index) {
      setSelectedCustomer(customers.find((x) => x._id === index._id));
    } else {
      setSelectedCustomer(null);
    }
  };

  const handleSetEditCustomer = (index, customer) => {
    setEditIndex(index);
    setEditName(customer.name);
    setEditStreet(customer.street);
    setEditNumber(customer.number);
    setEditPlz(customer.plz);
    setEditCity(customer.city);
  };

  const handleSetEditContact = (index, contact) => {
    setEditContactIndex(index);
    setEditContactGender(contact.gender);
    setEditContactPrename(contact.prename);
    setEditContactSurname(contact.surname);
    setEditContactManager(contact.manager);
    setEditContactEmail(contact.email);
    setEditContactPhone(contact.phone);
    setEditContactCraft(contact.gewerk);
  };

  const handleRemoveCustomer = (id) => {
    if (window.confirm("Diesen Kunden löschen?")) {
      removeCustomer(id);
    }
  };

  const handleRemoveContact = (id) => {
    if (window.confirm("Diesen Kontakt löschen?")) {
      removeContact(id);
    }
  };

  const handleEditCustomer = (id) => {
    const payload = {
      name: editname,
      street: editstreet,
      number: editnumber,
      plz: editplz,
      city: editcity,
    };
    updateCustomer(payload, id).then(() => {
      setEditIndex(null);
    });
  };

  const handleEditContact = (contact) => {
    const payload = {
      gender: editContactGender,
      prename: editContactPrename,
      surname: editContactSurname,
      manager: editContactManager,
      email: editContactEmail,
      phone: editContactPhone,
      gewerk: editContactCraft,
    };
    updateContact(payload, contact._id).then(() => {
      setEditContactIndex(null);
    });
  };

  const renderCustomers = () => {
    let filteredCustomers = customers.filter((data) =>
      selectedCustomer ? data._id === selectedCustomer._id : true,
    );
    return (
      <Stack direction={"column"} spacing={1}>
        {filteredCustomers.map((customer, index) => (
          <Box key={customer._id}>
            {editIndex !== customer._id ? (
              <Paper>
                <Stack direction={"column"} p={1} spacing={1}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      p={1}
                    >
                      <Stack direction={"column"}>
                        <Typography variant={"h7"}>{customer.name}</Typography>
                        {/*
                                        <Typography variant={'body2'}>{customer.street} - {customer.number}</Typography>
                                        <Typography variant={'body2'}>{customer.plz} - {customer.city}</Typography>
                                        */}
                      </Stack>
                    </Stack>
                    {me.role === "ADMIN" && (
                      <Stack
                        direction={"row"}
                        spacing={1}
                        justifyContent={"end"}
                      >
                        <Button
                          onClick={() =>
                            handleSetEditCustomer(customer._id, customer)
                          }
                          color={"primary"}
                        >
                          Bearbeiten
                        </Button>
                        <Button
                          onClick={() => handleRemoveCustomer(customer._id)}
                          color={"secondary"}
                        >
                          Löschen
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                  <Card>
                    <CardContent>
                      <Stack
                        direction={"column"}
                        p={1}
                        spacing={1}
                        divider={<Divider />}
                      >
                        {customer.contacts.map((contact, index) => (
                          <Box key={contact._id}>
                            {editContactIndex !== contact._id ? (
                              <Stack
                                direction={"row"}
                                spacing={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Stack direction={"column"} spacing={1}>
                                  <Stack direction={"column"}>
                                    <Typography
                                      variant={"body1"}
                                    >{`${contact.prename} ${contact.surname}`}</Typography>
                                    <Typography
                                      variant={"body2"}
                                    >{`${contact.email}`}</Typography>
                                    <Typography
                                      variant={"body2"}
                                    >{`${contact.phone}`}</Typography>
                                  </Stack>
                                  <Stack direction={"row"} spacing={1}>
                                    {contact.manager && (
                                      <Chip
                                        color={"primary"}
                                        key={index + "manager"}
                                        label={"Bauleiter"}
                                      />
                                    )}
                                    {contact.gewerk.length > 0 &&
                                      contact.gewerk.map((gewerk, index) => (
                                        <Chip key={index} label={gewerk} />
                                      ))}
                                  </Stack>
                                </Stack>
                                <Stack direction={"row"} spacing={1}>
                                  <Button
                                    onClick={() =>
                                      handleSetEditContact(contact._id, contact)
                                    }
                                    color={"primary"}
                                  >
                                    Bearbeiten
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleRemoveContact(contact._id)
                                    }
                                    color={"secondary"}
                                  >
                                    Löschen
                                  </Button>
                                </Stack>
                              </Stack>
                            ) : (
                              <Paper>
                                <Box p={1}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} md={2}>
                                      <FormControl fullWidth size={"medium"}>
                                        <InputLabel>Anrede</InputLabel>
                                        <Select
                                          id="anrede"
                                          value={editContactGender}
                                          label="Anrede"
                                          onChange={(e) =>
                                            setEditContactGender(e.target.value)
                                          }
                                        >
                                          <MenuItem value={"HERR"}>
                                            Herr
                                          </MenuItem>
                                          <MenuItem value={"FRAU"}>
                                            Frau
                                          </MenuItem>
                                          <MenuItem value={"DIVERS"}>
                                            Divers
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                      <TextField
                                        variant={"filled"}
                                        value={editContactPrename}
                                        onChange={(e) =>
                                          setEditContactPrename(e.target.value)
                                        }
                                        fullWidth
                                        label={"Vorname"}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                      <TextField
                                        variant={"filled"}
                                        value={editContactSurname}
                                        onChange={(e) =>
                                          setEditContactSurname(e.target.value)
                                        }
                                        fullWidth
                                        label={"Nachname"}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        variant={"filled"}
                                        value={editContactEmail}
                                        onChange={(e) =>
                                          setEditContactEmail(e.target.value)
                                        }
                                        fullWidth
                                        label={"Email"}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        variant={"filled"}
                                        value={editContactPhone}
                                        onChange={(e) =>
                                          setEditContactPhone(e.target.value)
                                        }
                                        fullWidth
                                        label={"Telefon"}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        multiple
                                        id="gewerk"
                                        value={
                                          editContactCraft
                                            ? editContactCraft
                                            : []
                                        }
                                        onChange={(e, value) =>
                                          setEditContactCraft(value)
                                        }
                                        freeSolo
                                        autoSelect
                                        options={[
                                          "Maler",
                                          "Bodenbelag",
                                          "Fliesen",
                                          "Eigengewerk",
                                          "Hausanschluss",
                                          "Lüftung",
                                          "Heizung",
                                          "Sanitär",
                                          "Klima",
                                          "Elektro",
                                        ]}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Gewerke"
                                            variant="filled"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Stack
                                        direction={"row"}
                                        spacing={1}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={editContactManager}
                                              onChange={(e) =>
                                                setEditContactManager(
                                                  e.target.checked,
                                                )
                                              }
                                            />
                                          }
                                          label="Bauleiter"
                                        />
                                        <Stack direction={"row"} spacing={1}>
                                          <Button
                                            onClick={() =>
                                              setEditContactIndex(null)
                                            }
                                            color={"secondary"}
                                          >
                                            Abbrechen
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handleEditContact(contact)
                                            }
                                          >
                                            Speichern
                                          </Button>
                                        </Stack>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Paper>
                            )}
                          </Box>
                        ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </Stack>
              </Paper>
            ) : (
              <Stack direction={"column"} spacing={1}>
                <Paper key={index}>
                  <Box p={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          variant={"filled"}
                          value={editname}
                          onChange={(e) => setEditName(e.target.value)}
                          fullWidth
                          label={"Name"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant={"filled"}
                          value={editstreet}
                          onChange={(e) => setEditStreet(e.target.value)}
                          fullWidth
                          label={"Straße"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant={"filled"}
                          value={editnumber}
                          onChange={(e) => setEditNumber(e.target.value)}
                          fullWidth
                          label={"Hausnr."}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant={"filled"}
                          value={editplz}
                          onChange={(e) => setEditPlz(e.target.value)}
                          fullWidth
                          label={"PLZ"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant={"filled"}
                          value={editcity}
                          onChange={(e) => setEditCity(e.target.value)}
                          fullWidth
                          label={"Ort"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          display={"flex"}
                          justifyContent={"end"}
                        >
                          <Button
                            onClick={() => setEditIndex(null)}
                            color={"secondary"}
                          >
                            Abbrechen
                          </Button>
                          <Button
                            onClick={() => handleEditCustomer(customer._id)}
                          >
                            Speichern
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Stack>
            )}
          </Box>
        ))}
      </Stack>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Paper>
        <Box p={1}>
          <Autocomplete
            id="employee"
            onChange={handleSelectCustomer}
            options={customers}
            fullWidth
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Kunden filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
        </Box>
      </Paper>
      {renderCustomers()}
      <NewContact />
    </Stack>
  );
};

export default Customers;
