import { useEffect, useState } from "react";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { GET_ALL_FILES } from "../queries/File/getAllFiles";
import { UPLOAD_FILE } from "../mutations/File/uploadFile";
import { REMOVE_FILE } from "../mutations/File/removeFile";
import { ON_FILES_CHANGED } from "../subscriptions/onFilesChanged";

const query = GET_ALL_FILES;
const uploadMutation = UPLOAD_FILE;
const removeMutation = REMOVE_FILE;
const subscription = ON_FILES_CHANGED;

const entityName = "file";

export const useFiles = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    upload,
    { loading: uploadMutationLoading, error: uploadMutationError },
  ] = useMutation(uploadMutation, {
    onCompleted: () => {
      console.log(entityName + " uploaded!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onFilesChanged);
      queryRefetch();
    },
  });

  const executeFileQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllFiles);
    }
  }, [queryData]);

  return {
    loading: queryLoading || uploadMutationLoading || removeMutationLoading,
    error: queryError || uploadMutationError || removeMutationError,
    files: data,
    executeFileQuery,
    uploadFile: (input, file) =>
      upload({
        variables: {
          input: input,
          file: file,
        },
      }),
    removeFile: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
  };
};
