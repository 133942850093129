import { gql } from "@apollo/client";
import { APARTMENT_ENTITY } from "../../entities/apartmentEntity";

export const CREATE_APARTMENT = gql`
  mutation createApartment($input: ApartmentInput!) {
    createApartment(input: $input) {
      ...ApartmentEntity
    }
  }
  ${APARTMENT_ENTITY}
`;
