import { gql } from "@apollo/client";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";

export const UPDATE_BUILDING = gql`
  mutation updateBuilding($input: BuildingInput!, $id: String!) {
    updateBuilding(input: $input, id: $id) {
      ...BuildingEntity
    }
  }
  ${BUILDING_ENTITY}
`;
