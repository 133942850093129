import { gql } from "@apollo/client";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";

export const CREATE_BUILDING = gql`
  mutation createBuilding($input: BuildingInput!) {
    createBuilding(input: $input) {
      ...BuildingEntity
    }
  }
  ${BUILDING_ENTITY}
`;
