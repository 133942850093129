import * as React from "react";
import { useEffect, useState } from "react";
import Section from "../Section";
import FullPageLoader from "../common/FullPageLoader";
import { useParams } from "react-router-dom";
import NewEntry from "./NewEntry";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Fab,
  FormControl,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import PDF from "../common/PDF";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { ProtocolTypes, SectionTypeText } from "../../types";
import { IoFilter } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { uniq } from "../../uniq";
import Contact from "../common/Contact";
import { shadeColor } from "../../shade";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useEntries } from "../../apollo/hooks/useEntries";
import { useCustomers } from "../../apollo/hooks/useCustomers";
import { useContacts } from "../../apollo/hooks/useContacts";
import { useProtocols } from "../../apollo/hooks/useProtocols";
import SendEntries from "./SendEntries";
import styles from "./entrys.module.css";
import ProtocolDetails from "./ProtocolDetails";
import PDF_Uebergabe from "../common/PDF/PDF_Uebergabe";

const generatedColor = (entry) => {
  return (entry.state === "OPEN" || entry.state === "IN_PROGRESS") &&
    moment().isSameOrAfter(moment(entry.deadline))
    ? "#d21f3c"
    : entry.state === "IN_PROGRESS"
      ? "#f19c1c"
      : entry.state === "FINISHED"
        ? "#5aae00"
        : "#8c8c8c";
};

const generatedBackgroundColor = (entry) => {
  return (entry.state === "OPEN" || entry.state === "IN_PROGRESS") &&
    moment().isSameOrAfter(moment(entry.deadline))
    ? "#ffdbdf"
    : entry.state === "IN_PROGRESS"
      ? "#fdefd8"
      : entry.state === "FINISHED"
        ? "#e7ffd1"
        : "#ffffff";
};

const filterEntries = (
  entries,
  protocol,
  showAll,
  entryFilter,
  showSent,
  selectedCustomer,
  selectedContact,
  selectedLevel,
  selectedGewerk,
  selectedCustomerProp,
  selectedContactProp,
  selectedLevelProp,
  selectedGewerkProp,
) => {
  const filterEntryState = (state) => {
    if (showAll) {
      if (state !== "FINISHED") return true;
    } else {
      if (entryFilter === state) return true;
      if (entryFilter === "ALL") return true;
    }
  };

  if (entries)
    return entries
      .filter((entry) => filterEntryState(entry.state))
      .filter((entry) => (showSent ? entry.mails.length >= 1 : true))
      .filter((entry) => entry.protocol._id === protocol?._id)
      .filter((entry) =>
        selectedCustomer
          ? entry.contacts.find((x) => x.customer._id === selectedCustomer._id)
          : true,
      )
      .filter((entry) =>
        selectedContact
          ? entry.contacts.find((x) => x._id === selectedContact._id)
          : true,
      )
      .filter((entry) => (selectedLevel ? entry.level === selectedLevel : true))
      .filter((entry) =>
        selectedGewerk
          ? entry.contacts.find((x) =>
              x.gewerk.find((c) => c === selectedGewerk),
            )
          : true,
      )
      .filter((entry) =>
        selectedCustomerProp
          ? entry.contacts.find(
              (x) => x.customer._id === selectedCustomerProp._id,
            )
          : true,
      )
      .filter((entry) =>
        selectedContactProp
          ? entry.contacts.find((x) => x._id === selectedContactProp._id)
          : true,
      )
      .filter((entry) =>
        selectedLevelProp ? entry.level === selectedLevelProp : true,
      )
      .filter((entry) =>
        selectedGewerkProp
          ? entry.contacts.find((x) =>
              x.gewerk.find((c) => c === selectedGewerkProp),
            )
          : true,
      );
};

const RenderFiles = (props) => {
  const [show, setShow] = React.useState(null);
  const FileViewer = () => {
    return (
      <Box
        position={"absolute"}
        sx={{ top: 0, left: 0, width: "100%", height: "100%", zIndex: 99999 }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        className={styles.fileViewer}
        onClick={() => setShow(null)}
      >
        <Box
          position={"relative"}
          sx={{
            height: "90vh",
          }}
        >
          <img
            height={"100%"}
            src={`${window.env.FILES_URL}${props.entry._id}/${show.filename}`}
            alt={"image"}
          />
        </Box>
      </Box>
    );
  };
  return (
    <Stack direction={"column"}>
      {show && <FileViewer />}
      {props.files.map((file, index) => (
        <Typography
          sx={{ color: generatedColor(props.entry), cursor: "pointer" }}
          key={index}
          variant={"body2"}
          onClick={() => setShow(file)}
        >{`Bild ${index + 1}`}</Typography>
      ))}
    </Stack>
  );
};

const DownloadMenu = ({ protocol, entries, loading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ProtocolSectionName = {
    PROJECT: protocol.project?.name,
    FIELD: protocol.field?.name,
    BUILDING: protocol.building?.name,
    APARTMENT: protocol.apartment?.name,
  };

  const PdfStandard = ({ withImages }) => {
    return (
      <PDF
        withImages={withImages}
        title={`${ProtocolTypes[protocol.type]} ${SectionTypeText[protocol.section]} "${ProtocolSectionName[protocol.section]}"`}
        subtitle={`Erstellt am ${moment(protocol.created_at).format("DD.MM.YYYY HH:mm")} Uhr`}
        items={entries.filter((entry) => !entry.hidden)}
      />
    );
  };

  const PdfUebergabe = ({ withImages }) => {
    return (
      <PDF_Uebergabe
        withImages={withImages}
        title={`${ProtocolTypes[protocol.type]} ${SectionTypeText[protocol.section]} "${ProtocolSectionName[protocol.section]}"`}
        subtitle={`Erstellt am ${moment(protocol.created_at).format("DD.MM.YYYY HH:mm")} Uhr`}
        items={entries.filter((entry) => !entry.hidden)}
        protocol={protocol}
      />
    );
  };

  const pdfTypeWithoutImages = {
    BEGEHUNG: <PdfStandard withImages={false} />,
    BESPRECHUNG: <PdfStandard withImages={false} />,
    FESTLEGUNG: <PdfStandard withImages={false} />,
    MAENGEL: <PdfStandard withImages={false} />,
    TAGESBERICHT: <PdfStandard withImages={false} />,
    UEBERGABE: <PdfUebergabe withImages={false} />,
  };

  const pdfTypeWithImages = {
    BEGEHUNG: <PdfStandard withImages={true} />,
    BESPRECHUNG: <PdfStandard withImages={true} />,
    FESTLEGUNG: <PdfStandard withImages={true} />,
    MAENGEL: <PdfStandard withImages={true} />,
    TAGESBERICHT: <PdfStandard withImages={true} />,
    UEBERGABE: <PdfUebergabe withImages={true} />,
  };

  return (
    <Box
      textAlign={"right"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"end"}
      sx={{ height: "100%" }}
    >
      <Fab size={"small"} color={"primary"} onClick={handleClick}>
        <FiDownload />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple>
          <PDFDownloadLink
            onClick={(e) => loading && e.preventDefault()}
            style={{ textDecoration: "none" }}
            document={pdfTypeWithoutImages[protocol.type]}
            fileName={
              ProtocolTypes[protocol.type] +
              " " +
              SectionTypeText[protocol.section] +
              " " +
              ProtocolSectionName[protocol.section]?.replace(".", "-") +
              " " +
              moment(protocol.created_at).format("DD_MM_YYYY")
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <Typography disabled>Lade Daten...</Typography>
              ) : (
                <Typography>Ohne Dateien</Typography>
              )
            }
          </PDFDownloadLink>
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem disableRipple>
          <PDFDownloadLink
            onClick={(e) => loading && e.preventDefault()}
            style={{ textDecoration: "none" }}
            document={pdfTypeWithImages[protocol.type]}
            fileName={
              ProtocolTypes[protocol.type] +
              " " +
              SectionTypeText[protocol.section] +
              " " +
              ProtocolSectionName[protocol.section]?.replace(".", "-") +
              " " +
              moment(protocol.created_at).format("DD_MM_YYYY")
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <Typography disabled>Lade Daten...</Typography>
              ) : (
                <Typography>Mit Dateien</Typography>
              )
            }
          </PDFDownloadLink>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const maengelTableHead = (noEdit) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Bereich</TableCell>
        <TableCell>Verantwortlich</TableCell>
        <TableCell>Bemerkung</TableCell>
        <TableCell>Termin</TableCell>
        <TableCell>Dateien</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Ausblenden</TableCell>
        {!noEdit && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

const begehungTableHead = (noEdit) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Thema</TableCell>
        <TableCell>Verantwortlich</TableCell>
        <TableCell>Termin</TableCell>
        <TableCell>Bemerkung</TableCell>
        <TableCell>Dateien</TableCell>
        <TableCell>Status</TableCell>
        {!noEdit && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

const uebergabeTableHead = (noEdit) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Bereich</TableCell>
        <TableCell>Bemerkung</TableCell>
        <TableCell>Dateien</TableCell>
        {!noEdit && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

const berichtTableHead = (noEdit) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Verantwortlich</TableCell>
        <TableCell>AK-Anzahl</TableCell>
        <TableCell>Baufeld/Haus</TableCell>
        <TableCell>Aufgabe</TableCell>
        <TableCell>Stunden</TableCell>
        <TableCell>Bemerkung</TableCell>
        {!noEdit && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

const MaengelTableBody = ({
  entries,
  noEdit,
  update,
  updateHidden,
  remove,
}) => {
  return (
    <TableBody>
      {entries?.map((entry) => (
        <React.Fragment>
          <TableRow
            key={entry._id}
            sx={{
              ".MuiTableCell-root": { borderBottom: "unset" },
              backgroundColor: generatedBackgroundColor(entry),
              borderLeft: "10px solid " + generatedColor(entry),
            }}
          >
            <TableCell>
              <Stack direction={"column"}>
                <Typography
                  sx={{
                    color: shadeColor(generatedColor(entry).toString(), -50),
                  }}
                >
                  {entry.level}
                </Typography>
                <Typography
                  sx={{ color: generatedColor(entry) }}
                  variant={"body2"}
                >
                  {entry.room}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction={"column"} spacing={1}>
                {entry.contacts.length > 0 &&
                  entry.contacts.map((contact) => (
                    <Box key={contact._id}>
                      <Contact
                        contactId={contact._id}
                        color={generatedColor(entry)}
                      />
                    </Box>
                  ))}
              </Stack>
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {noEdit ? entry.comment : entry.comment.substr(0, 50) + "..."}
            </TableCell>
            <TableCell>
              <Stack direction={"column"} spacing={1}>
                {entry.deadline && (
                  <Typography
                    sx={{
                      color: shadeColor(generatedColor(entry).toString(), -50),
                      textDecoration:
                        entry.mails.some((mail) => mail.grace_period) &&
                        "line-through",
                    }}
                  >
                    {moment(entry.deadline).format("L")}
                  </Typography>
                )}
                {entry.mails.length > 0 && (
                  <Stack direction={"column"} spacing={1}>
                    <Tooltip
                      title={
                        <Stack
                          direction={"column"}
                          spacing={1}
                          divider={
                            <Divider style={{ backgroundColor: "#fff" }} />
                          }
                        >
                          {entry.mails
                            .filter((mail) => !mail.grace_period)
                            .map((mail, index) => (
                              <Stack direction={"column"} spacing={1}>
                                <Stack direction={"column"}>
                                  <Typography variant={"body1"} color={"#fff"}>
                                    {index +
                                      1 +
                                      ". mal Versendet von " +
                                      mail.user?.prename +
                                      " " +
                                      mail.user?.surname}
                                  </Typography>
                                  <Typography
                                    variant={"body2"}
                                    color={"#a2a2a2"}
                                  >
                                    {moment(mail.created_at).format("LLLL")}
                                  </Typography>
                                </Stack>
                                <Stack direction={"column"}>
                                  <Typography variant={"body1"} color={"#fff"}>
                                    bcc:
                                  </Typography>
                                  {mail.bcc.map((email) => (
                                    <Typography
                                      variant={"body2"}
                                      color={"#a2a2a2"}
                                    >
                                      {email}
                                    </Typography>
                                  ))}
                                </Stack>
                              </Stack>
                            ))}
                        </Stack>
                      }
                    >
                      <Typography
                        variant={"body2"}
                        sx={{ color: generatedColor(entry) }}
                      >
                        {"Versendet, Frist: " +
                          moment(
                            entry.mails
                              .filter((mail) => !mail.grace_period)
                              .at(-1)?.created_at,
                          )
                            .add(2, "weeks")
                            .format("L")}
                      </Typography>
                    </Tooltip>
                    {entry.mails.some((mail) => mail.grace_period) && (
                      <Tooltip
                        title={
                          <Stack
                            direction={"column"}
                            spacing={1}
                            divider={
                              <Divider style={{ backgroundColor: "#fff" }} />
                            }
                          >
                            {entry.mails
                              .filter((mail) => mail.grace_period)
                              .map((mail, index) => (
                                <Stack direction={"column"} spacing={1}>
                                  <Stack direction={"column"}>
                                    <Typography
                                      variant={"body1"}
                                      color={"#fff"}
                                    >
                                      {index +
                                        1 +
                                        ". mal Versendet von " +
                                        mail.user?.prename +
                                        " " +
                                        mail.user?.surname}
                                    </Typography>
                                    <Typography
                                      variant={"body2"}
                                      color={"#a2a2a2"}
                                    >
                                      {moment(mail.created_at).format("LLLL")}
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"column"}>
                                    <Typography
                                      variant={"body1"}
                                      color={"#fff"}
                                    >
                                      bcc:
                                    </Typography>
                                    {mail.bcc.map((email) => (
                                      <Typography
                                        variant={"body2"}
                                        color={"#a2a2a2"}
                                      >
                                        {email}
                                      </Typography>
                                    ))}
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>
                        }
                      >
                        <Typography
                          variant={"body2"}
                          sx={{ color: generatedColor(entry) }}
                        >
                          {"Versendet, Nachfrist: " +
                            moment(
                              entry.mails
                                .filter((mail) => mail.grace_period)
                                .at(-1)?.created_at,
                            )
                              .add(2, "weeks")
                              .format("L")}
                        </Typography>
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </Stack>
            </TableCell>
            <TableCell>
              <RenderFiles entry={entry} files={entry.files} />
            </TableCell>
            <TableCell>
              <FormControl fullWidth size={"small"}>
                <Select
                  sx={{
                    color: shadeColor(generatedColor(entry).toString(), -50),
                  }}
                  value={entry.state}
                  onChange={(e) => update(entry, e.target.value)}
                >
                  <MenuItem value={"OPEN"}>Offen</MenuItem>
                  <MenuItem value={"IN_PROGRESS"}>In Bearbeitung</MenuItem>
                  <MenuItem value={"FINISHED"}>Fertiggestellt</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <Checkbox
                checked={entry.hidden}
                onClick={() => updateHidden(entry)}
              ></Checkbox>
            </TableCell>
            {!noEdit && (
              <TableCell>
                <Button color={"secondary"} onClick={() => remove(entry)}>
                  Löschen
                </Button>
              </TableCell>
            )}
          </TableRow>
          <TableRow
            style={{
              backgroundColor: shadeColor(
                generatedBackgroundColor(entry).toString(),
                -3,
              ),
              borderLeft: "10px solid " + generatedColor(entry),
            }}
          >
            <TableCell colSpan={7}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    color: generatedColor(entry).toString(),
                  }}
                  variant={"body2"}
                >
                  {"Eintrag erstellt von " +
                    entry.user?.prename +
                    " " +
                    entry.user?.surname +
                    " am " +
                    moment(entry.created_at).format("DD.MM.YYYY HH:mm")}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  );
};

const BegehungTableBody = ({
  entries,
  noEdit,
  update,
  updateHidden,
  remove,
}) => {
  return (
    <TableBody>
      {entries?.map((entry) => (
        <React.Fragment>
          <TableRow
            key={entry._id}
            sx={{
              ".MuiTableCell-root": { borderBottom: "unset" },
              backgroundColor: generatedBackgroundColor(entry),
              borderLeft: "10px solid " + generatedColor(entry),
            }}
          >
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {entry.topic}
            </TableCell>
            <TableCell>
              <Stack direction={"column"} spacing={1}>
                {entry.contacts.length > 0 &&
                  entry.contacts.map((contact) => (
                    <Box key={contact._id}>
                      <Contact
                        contactId={contact._id}
                        color={generatedColor(entry)}
                      />
                    </Box>
                  ))}
              </Stack>
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {entry.deadline && moment(entry.deadline).format("L")}
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {noEdit ? entry.comment : entry.comment.substr(0, 50) + "..."}
            </TableCell>
            <TableCell>
              <RenderFiles entry={entry} files={entry.files} />
            </TableCell>
            <TableCell>
              <FormControl fullWidth size={"small"}>
                <Select
                  sx={{
                    color: shadeColor(generatedColor(entry).toString(), -50),
                  }}
                  value={entry.state}
                  onChange={(e) => update(entry, e.target.value)}
                >
                  <MenuItem value={"OPEN"}>Offen</MenuItem>
                  <MenuItem value={"IN_PROGRESS"}>In Bearbeitung</MenuItem>
                  <MenuItem value={"FINISHED"}>Fertiggestellt</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            {!noEdit && (
              <TableCell>
                <Button color={"secondary"} onClick={() => remove(entry._id)}>
                  Löschen
                </Button>
              </TableCell>
            )}
          </TableRow>
          <TableRow
            style={{
              backgroundColor: shadeColor(
                generatedBackgroundColor(entry).toString(),
                -3,
              ),
              borderLeft: "10px solid " + generatedColor(entry),
            }}
          >
            <TableCell colSpan={7}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    color: generatedColor(entry).toString(),
                  }}
                  variant={"body2"}
                >
                  {"Eintrag erstellt von " +
                    entry.user?.prename +
                    " " +
                    entry.user?.surname +
                    " am " +
                    moment(entry.created_at).format("DD.MM.YYYY HH:mm")}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  );
};

const BerichtTableBody = ({
  entries,
  noEdit,
  update,
  updateHidden,
  remove,
}) => {
  return (
    <TableBody>
      {entries?.map((entry) => (
        <>
          <TableRow
            key={entry._id}
            sx={{ ".MuiTableCell-root": { borderBottom: "unset" } }}
          >
            <TableCell>
              <Stack direction={"column"} spacing={1}>
                {entry.contacts.length > 0 &&
                  entry.contacts.map((contact) => (
                    <Box key={contact._id}>
                      <Contact
                        contactId={contact._id}
                        color={generatedColor(entry)}
                      />
                    </Box>
                  ))}
              </Stack>
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {entry.workers}
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {entry.section}
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {entry.topic}
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {entry.hours}h
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {noEdit ? entry.comment : entry.comment.substr(0, 50) + "..."}
            </TableCell>
            {!noEdit && (
              <TableCell>
                <Button color={"secondary"} onClick={() => remove(entry._id)}>
                  Löschen
                </Button>
              </TableCell>
            )}
          </TableRow>
          <TableRow
            style={{
              backgroundColor: shadeColor(generatedBackgroundColor(entry), -3),
              borderLeft: "10px solid " + generatedColor(entry),
            }}
          >
            <TableCell colSpan={7}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    color: generatedColor(entry).toString(),
                  }}
                  variant={"body2"}
                >
                  {"Eintrag erstellt von " +
                    entry.user?.prename +
                    " " +
                    entry.user?.surname +
                    " am " +
                    moment(entry.created_at).format("DD.MM.YYYY HH:mm")}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </>
      ))}
    </TableBody>
  );
};

const UebergabeTableBody = ({
  entries,
  noEdit,
  update,
  updateHidden,
  remove,
}) => {
  return (
    <TableBody>
      {entries?.map((entry) => (
        <React.Fragment>
          <TableRow
            key={entry._id}
            sx={{
              ".MuiTableCell-root": { borderBottom: "unset" },
              backgroundColor: generatedBackgroundColor(entry),
            }}
          >
            <TableCell>
              <Stack direction={"column"}>
                <Typography
                  sx={{
                    color: shadeColor(generatedColor(entry).toString(), -50),
                  }}
                >
                  {entry.level}
                </Typography>
                <Typography
                  sx={{ color: generatedColor(entry) }}
                  variant={"body2"}
                >
                  {entry.room}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell
              sx={{
                color: shadeColor(generatedColor(entry).toString(), -50),
              }}
            >
              {noEdit ? entry.comment : entry.comment.substr(0, 50) + "..."}
            </TableCell>
            <TableCell>
              <RenderFiles entry={entry} files={entry.files} />
            </TableCell>
            {!noEdit && (
              <TableCell>
                <Button color={"secondary"} onClick={() => remove(entry)}>
                  Löschen
                </Button>
              </TableCell>
            )}
          </TableRow>
          <TableRow
            style={{
              backgroundColor: shadeColor(
                generatedBackgroundColor(entry).toString(),
                -3,
              ),
            }}
          >
            <TableCell colSpan={7}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    color: generatedColor(entry).toString(),
                  }}
                  variant={"body2"}
                >
                  {"Eintrag erstellt von " +
                    entry.user?.prename +
                    " " +
                    entry.user?.surname +
                    " am " +
                    moment(entry.created_at).format("DD.MM.YYYY HH:mm")}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  );
};

const Entrys = (props) => {
  const routeParams = useParams();
  const { me, executeUserSelfQuery, error: usersError } = useUsers();
  const {
    protocols,
    executeProtocolQuery,
    error: protocolsError,
  } = useProtocols();
  const {
    entries,
    executeEntryQuery,
    removeEntry,
    updateEntryState,
    updateEntryHidden,
    error: entrysError,
  } = useEntries();
  const { contacts, executeContactQuery, error: contactsError } = useContacts();
  const {
    customers,
    executeCustomerQuery,
    error: customersError,
  } = useCustomers();
  const [loading, setLoading] = useState(true);
  const [protocol, setProtocol] = useState({});
  const [entryFilter, setEntryFilter] = useState("ALL");

  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedGewerk, setSelectedGewerk] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [filteredEntriesArray, setFilteredEntriesArray] = useState(null);

  useEffect(() => {
    executeUserSelfQuery();
    executeEntryQuery();
    executeCustomerQuery();
    executeContactQuery();
    executeProtocolQuery();
  }, []);

  /* eslint-disable */
  useEffect(() => {
    if (protocols) {
      if (props.protocolId) {
        setProtocol(
          protocols.find((protocol) => protocol._id === props.protocolId),
        );
      } else {
        setProtocol(
          protocols.find((protocol) => protocol._id === routeParams.protocolId),
        );
      }
      setLoading(false);
    }
  }, [protocols]);
  /* eslint-enable */

  useEffect(() => {
    if (entries) {
      setFilteredEntriesArray(
        filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        ),
      );
    }
  }, [entries, protocol]);

  useEffect(() => {
    filteredEntriesArray &&
      props.onFilterChanged &&
      props.onFilterChanged(filteredEntriesArray);
  }, [filteredEntriesArray]);

  if (
    loading ||
    !protocol ||
    !me ||
    !entries ||
    !customers ||
    !contacts ||
    !protocols ||
    !filteredEntriesArray
  )
    return <FullPageLoader position={"relative"} />;
  if (
    protocolsError ||
    entrysError ||
    contactsError ||
    customersError ||
    usersError
  )
    return <FullPageLoader error />;

  const SectionTypes = {
    PROJECT: <Section id={protocol?.project?._id} />,
    FIELD: <Section id={protocol?.field?._id} />,
    BUILDING: <Section id={protocol?.building?._id} />,
    APARTMENT: <Section id={protocol?.apartment?._id} />,
  };

  const handleRemoveEntry = (entry) => {
    if (window.confirm(`Möchtest du diesen Eintrag wirklich löschen?`)) {
      removeEntry(entry._id);
    }
  };

  const handleUpdateEntryState = (entry, state) => {
    const payload = {
      state: state,
    };
    updateEntryState(payload, entry._id);
  };

  const handleUpdateEntryHidden = (entry) => {
    const payload = {
      hidden: !entry.hidden,
    };
    updateEntryHidden(payload, entry._id);
  };

  const ProtocolTypeTableHead = {
    BEGEHUNG: begehungTableHead(props.noEdit),
    BESPRECHUNG: begehungTableHead(props.noEdit),
    FESTLEGUNG: begehungTableHead(props.noEdit),
    MAENGEL: maengelTableHead(props.noEdit),
    TAGESBERICHT: berichtTableHead(props.noEdit),
    UEBERGABE: uebergabeTableHead(props.noEdit),
  };

  const ProtocolTypeTableBody = {
    BEGEHUNG: (
      <BegehungTableBody
        entries={filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        )}
        noEdit={props.noEdit}
        update={handleUpdateEntryState}
        updateHidden={handleUpdateEntryHidden}
        remove={handleRemoveEntry}
      />
    ),
    BESPRECHUNG: (
      <BegehungTableBody
        entries={filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        )}
        noEdit={props.noEdit}
        update={handleUpdateEntryState}
        updateHidden={handleUpdateEntryHidden}
        remove={handleRemoveEntry}
      />
    ),
    FESTLEGUNG: (
      <BegehungTableBody
        entries={filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        )}
        noEdit={props.noEdit}
        update={handleUpdateEntryState}
        updateHidden={handleUpdateEntryHidden}
        remove={handleRemoveEntry}
      />
    ),
    MAENGEL: (
      <MaengelTableBody
        entries={filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        )}
        noEdit={props.noEdit}
        update={handleUpdateEntryState}
        updateHidden={handleUpdateEntryHidden}
        remove={handleRemoveEntry}
      />
    ),
    TAGESBERICHT: (
      <BerichtTableBody
        entries={filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        )}
        noEdit={props.noEdit}
        update={handleUpdateEntryState}
        updateHidden={handleUpdateEntryHidden}
        remove={handleRemoveEntry}
      />
    ),
    UEBERGABE: (
      <UebergabeTableBody
        entries={filterEntries(
          entries,
          protocol,
          props.showAll,
          entryFilter,
          props.showSent,
          selectedCustomer,
          selectedContact,
          selectedLevel,
          selectedGewerk,
          props.selectedCustomer,
          props.selectedContact,
          props.selectedLevel,
          props.selectedGewerk,
        )}
        noEdit={props.noEdit}
        update={handleUpdateEntryState}
        updateHidden={handleUpdateEntryHidden}
        remove={handleRemoveEntry}
      />
    ),
  };

  const FilterForm = () => {
    const handleSelectCustomer = (event, index) => {
      if (index) {
        setSelectedCustomer(customers.find((x) => x._id === index._id));
      } else {
        setSelectedCustomer(null);
      }
      setSelectedContact(null);
    };
    const handleSelectContact = (event, index) => {
      if (index) {
        setSelectedContact(contacts.find((x) => x._id === index._id));
      } else {
        setSelectedContact(null);
      }
    };
    const handleSelectLevel = (event, index) => {
      if (index) {
        setSelectedLevel(index);
      } else {
        setSelectedLevel(null);
      }
    };
    const handleSelectGewerk = (event, index) => {
      if (index) {
        setSelectedGewerk(index);
      } else {
        setSelectedGewerk(null);
      }
    };

    return (
      <Stack direction={"column"} spacing={1} p={1}>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            id="customer"
            onChange={handleSelectCustomer}
            options={customers}
            fullWidth
            value={selectedCustomer}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Firma filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
          <Autocomplete
            id="contact"
            onChange={handleSelectContact}
            options={selectedCustomer?.contacts}
            disabled={!selectedCustomer}
            fullWidth
            value={selectedContact}
            getOptionLabel={(option) =>
              option.gender + " " + option.prename + " " + option.surname
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Kontakt filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            id="level"
            onChange={handleSelectLevel}
            options={uniq(
              entries
                .filter(
                  (entry) => entry.protocol._id === routeParams.protocolId,
                )
                .map(({ level }) => level),
            )}
            fullWidth
            value={selectedLevel}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Level filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
          <Autocomplete
            id="gewerk"
            onChange={handleSelectGewerk}
            options={uniq(
              [].concat(...contacts.map((contact) => contact.gewerk)),
            )}
            fullWidth
            value={selectedGewerk}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Gewerk filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
        </Stack>
      </Stack>
    );
  };

  const FilterButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleResetFilter = () => {
      setSelectedContact(null);
      setSelectedCustomer(null);
      setEntryFilter("OPEN");
      setSelectedLevel(null);
      setSelectedGewerk(null);
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Stack direction={"column"} spacing={1} alignItems={"start"}>
          <Button size={"small"} color={"primary"} onClick={handleClick}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <IoFilter />
              <Box>Filter</Box>
            </Stack>
          </Button>
          <Button color={"secondary"} onClick={() => handleResetFilter()}>
            Zurücksetzen
          </Button>
        </Stack>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper sx={{ minWidth: 250 }}>
            <Stack direction={"column"} spacing={1} p={1}>
              <Button
                disabled={entryFilter === "OPEN"}
                onClick={() => setEntryFilter("OPEN")}
              >
                Offen
              </Button>
              <Button
                disabled={entryFilter === "IN_PROGRESS"}
                onClick={() => setEntryFilter("IN_PROGRESS")}
              >
                In Bearbeitung
              </Button>
              <Button
                disabled={entryFilter === "FINISHED"}
                onClick={() => setEntryFilter("FINISHED")}
              >
                Fertiggestellt
              </Button>
              <Button
                disabled={entryFilter === "ALL"}
                onClick={() => setEntryFilter("ALL")}
              >
                Alle
              </Button>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  return (
    <>
      {!props.minified && <NewEntry protocol={protocol} />}
      <Stack direction={"column"} spacing={1}>
        <Paper>
          <Stack direction={"column"} divider={<Divider />}>
            {!props.minified && protocol && SectionTypes[protocol.section]}

            {!props.minified && protocol.type !== "UEBERGABE" && <FilterForm />}
            {!props.minified && (
              <Stack
                direction={"row"}
                spacing={1}
                p={1}
                justifyContent={"space-between"}
              >
                {protocol.type !== "UEBERGABE" && <FilterButton />}
                <Stack direction={"column"} spacing={1}>
                  {protocol.type !== "UEBERGABE" && (
                    <SendEntries
                      protocol={protocol}
                      entries={filterEntries(
                        entries,
                        protocol,
                        props.showAll,
                        entryFilter,
                        props.showSent,
                        selectedCustomer,
                        selectedContact,
                        selectedLevel,
                        selectedGewerk,
                        props.selectedCustomer,
                        props.selectedContact,
                        props.selectedLevel,
                        props.selectedGewerk,
                      )}
                    />
                  )}
                  <DownloadMenu
                    protocol={protocol}
                    entries={filterEntries(
                      entries,
                      protocol,
                      props.showAll,
                      entryFilter,
                      props.showSent,
                      selectedCustomer,
                      selectedContact,
                      selectedLevel,
                      selectedGewerk,
                      props.selectedCustomer,
                      props.selectedContact,
                      props.selectedLevel,
                      props.selectedGewerk,
                    )}
                    loading={loading}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Paper>
        {!props.minified ? (
          <Paper>
            <Stack direction={"column"} p={1} textAlign={"center"}>
              <Typography variant={"h7"}>
                {ProtocolTypes[protocol.type]}
              </Typography>
              <Typography variant={"body2"}>
                {`Erstellt von ${protocol.user.prename} ${protocol.user.surname} am ${moment(protocol.created_at).format("DD.MM.YYYY HH:mm")}`}
              </Typography>
            </Stack>
            <Divider />
            {protocol.type === "UEBERGABE" && (
              <ProtocolDetails protocol={protocol} />
            )}
            <TableContainer>
              <Table size="small">
                {ProtocolTypeTableHead[protocol.type]}
                {ProtocolTypeTableBody[protocol.type]}
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <TableContainer>
            <Table size="small">
              {ProtocolTypeTableHead[protocol.type]}
              {ProtocolTypeTableBody[protocol.type]}
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default Entrys;
