import { gql } from "@apollo/client";
import { FIELD_ENTITY } from "../entities/fieldEntity";
import { BUILDING_ENTITY } from "../entities/buildingEntity";
import { APARTMENT_ENTITY } from "../entities/apartmentEntity";
import { PROTOCOL_ENTITY } from "../entities/protocolEntity";
import { PROJECT_ENTITY } from "../entities/projectEntity";

export const ON_BUILDINGS_CHANGED = gql`
  subscription {
    onBuildingsChanged {
        ...BuildingEntity
        field {
          ...FieldEntity
            project {
              ...ProjectEntity
            }
        }
        apartments {
          ...ApartmentEntity
        }
        protocols {
          ...ProtocolEntity
        }
    }
  }
  ${BUILDING_ENTITY},${FIELD_ENTITY},${APARTMENT_ENTITY},${PROTOCOL_ENTITY},${PROJECT_ENTITY}
`;
