import { gql } from "@apollo/client";
import { FIELD_ENTITY } from "../../entities/fieldEntity";

export const UPDATE_FIELD = gql`
  mutation updateField($input: FieldInput!, $id: String!) {
    updateField(input: $input, id: $id) {
      ...FieldEntity
    }
  }
  ${FIELD_ENTITY}
`;
