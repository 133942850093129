import { gql } from "@apollo/client";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const CREATE_ENTRY = gql`
  mutation createEntry($input: EntryInput!) {
    createEntry(input: $input) {
      ...EntryEntity
    }
  }
  ${ENTRY_ENTITY}
`;
