import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  Fab,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FileUpload from "../FileUpload";
import FullPageLoader from "../common/FullPageLoader";
import { useGlobalStore } from "../../store";
import Compressor from "compressorjs";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styles from "./entrys.module.css";
import { VscAdd } from "react-icons/vsc";
import NewContact from "../Customers/NewContact";
import { useEntries } from "../../apollo/hooks/useEntries";
import { useContacts } from "../../apollo/hooks/useContacts";
import { useFiles } from "../../apollo/hooks/useFiles";
import { useUsers } from "../../apollo/hooks/useUsers";

const NewEntry = ({ protocol }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const selectedFile = useGlobalStore((state) => state.selectedFile);
  const clearSelectedFile = useGlobalStore((state) => state.clearSelectedFile);

  const [topic, setTopic] = useState("");
  const [section, setSection] = useState("");
  const [hours, setHours] = useState(0);
  const [workers, setWorkers] = useState(0);
  const [level, setLevel] = useState("");
  const [room, setRoom] = useState("");
  const [responsible, setResponsible] = useState([]);
  const [comment, setComment] = useState("");
  const [deadline, setDeadline] = useState(null);

  const { addEntry, loading: entrysLoading, error: entrysError } = useEntries();
  const { uploadFile, loading: filesLoading, error: filesError } = useFiles();
  const {
    me,
    executeUserSelfQuery,
    loading: usersLoading,
    error: usersError,
  } = useUsers();
  const {
    contacts,
    executeContactQuery,
    loading: contactsLoading,
    error: contactsError,
  } = useContacts();

  useEffect(() => {
    protocol.type === "MAENGEL" && setDeadline(moment().add(2, "week"));
  }, [open]);

  useEffect(() => {
    executeContactQuery();
    executeUserSelfQuery();
  }, []);

  if (
    entrysLoading ||
    filesLoading ||
    usersLoading ||
    contactsLoading ||
    !contacts ||
    !me
  )
    return <FullPageLoader position={"relative"} />;
  if (entrysError || filesError || usersError || contactsError)
    return <FullPageLoader error />;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddEntry = () => {
    let payload = {
      user: me._id,
      topic: topic,
      section: section,
      hours: parseInt(hours),
      workers: parseInt(workers),
      level: level,
      room: room,
      contacts: responsible.length > 0 ? responsible.map((x) => x._id) : [],
      comment: comment,
      deadline: deadline,
      protocol: protocol._id,
    };
    addEntry(payload).then((res) => {
      if (res.data.createEntry._id) {
        selectedFile.map((file) => {
          const inputPayload = {
            filename: file.name,
            mimetype: file.type,
            entry: res.data?.createEntry._id,
            user: me._id,
          };
          new Compressor(file, {
            quality: 0.2,
            success: (compressedResult) => {
              uploadFile(inputPayload, compressedResult).then(() => {
                clearSelectedFile();
              });
            },
          });
          return true;
        });
      }
      setTopic("");
      setSection("");
      setHours(0);
      setWorkers(0);
      setComment("");
      setDeadline(null);
    });
  };

  const berichtMask = () => {
    return (
      <Grid container spacing={1}>
        {hours !== undefined && !Number.isInteger(parseInt(hours)) && (
          <Grid item xs={12}>
            <Alert severity={"error"}>Stunden muss eine Zahl sein!</Alert>
          </Grid>
        )}
        {workers !== undefined && !Number.isInteger(parseInt(workers)) && (
          <Grid item xs={12}>
            <Alert severity={"error"}>AK-Anzahl muss eine Zahl sein!</Alert>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={"Bereich"}
            variant={"filled"}
            value={section}
            onChange={(e) => setSection(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={"Thema"}
            variant={"filled"}
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label={"AK-Anzahl"}
            variant={"filled"}
            value={workers}
            onChange={(e) => setWorkers(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label={"Stunden"}
            variant={"filled"}
            value={hours}
            onChange={(e) => setHours(e.target.value)}
          />
        </Grid>
        <Grid item xs={9} md={9}>
          <Autocomplete
            multiple
            id="responsible"
            value={responsible ? responsible : []}
            onChange={(e, value) => setResponsible(value)}
            options={contacts}
            getOptionLabel={(option) =>
              option.prename +
              " " +
              option.surname +
              (option.customer ? " - " + option.customer.name : "") +
              (option.gewerk
                ? option.gewerk.length > 0
                  ? " - " + option.gewerk.map((x) => x)
                  : ""
                : "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Verantwortlich"
                variant="filled"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <NewContact normalButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label={"Bemerkung"}
            variant={"filled"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              disabled={!Number.isInteger(parseInt(hours))}
              onClick={() => handleAddEntry()}
            >
              Speichern
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const begehungMask = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Thema"}
            variant={"filled"}
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
          />
        </Grid>
        <Grid item xs={9} md={9}>
          <Autocomplete
            multiple
            id="responsible"
            value={responsible ? responsible : []}
            onChange={(e, value) => setResponsible(value)}
            options={contacts}
            getOptionLabel={(option) =>
              option.prename +
              " " +
              option.surname +
              (option.customer ? " - " + option.customer.name : "") +
              (option.gewerk
                ? option.gewerk.length > 0
                  ? " - " + option.gewerk.map((x) => x)
                  : ""
                : "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Verantwortlich"
                variant="filled"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <NewContact normalButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <MobileDatePicker
            minDate={moment()}
            disablePast
            slotProps={{
              textField: {
                label: "Termin bis",
                variant: "filled",
                fullWidth: true,
              },
            }}
            displayWeekNumber
            closeOnSelect
            value={deadline}
            onChange={(value) => {
              setDeadline(value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label={"Bemerkung"}
            variant={"filled"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUpload disablePermissions />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display={"flex"} justifyContent={"end"}>
            <Button onClick={() => handleAddEntry()}>Speichern</Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const maengelMask = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="level"
            value={level}
            onChange={(e, value) => setLevel(value)}
            freeSolo
            autoSelect
            options={[
              "Allgemein",
              "UG",
              "EG",
              "OG",
              "DG",
              "Spitzboden",
              "Außenanlagen",
              "1.OG",
              "2.OG",
              "3.OG",
              "4.OG",
            ]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Level" variant="filled" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="room"
            value={room}
            onChange={(e, value) => setRoom(value)}
            freeSolo
            autoSelect
            options={[
              "Diele",
              "Gäste-WC",
              "Flur",
              "HWR",
              "Wohnzimmer",
              "Küche",
              "Bad",
              "Zimmer links",
              "Zimmer rechts",
            ]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zimmer"
                variant="filled"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={9} md={9}>
          <Autocomplete
            multiple
            id="responsible"
            value={responsible ? responsible : []}
            onChange={(e, value) => setResponsible(value)}
            options={contacts}
            getOptionLabel={(option) =>
              option.prename +
              " " +
              option.surname +
              (option.customer ? " - " + option.customer.name : "") +
              (option.gewerk
                ? option.gewerk.length > 0
                  ? " - " + option.gewerk.map((x) => x)
                  : ""
                : "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Verantwortlich"
                variant="filled"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <NewContact normalButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <MobileDatePicker
            minDate={moment()}
            disablePast
            slotProps={{
              textField: {
                label: "Termin bis",
                variant: "filled",
                fullWidth: true,
              },
            }}
            displayWeekNumber
            closeOnSelect
            value={deadline}
            onChange={(value) => {
              setDeadline(value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label={"Bemerkung"}
            variant={"filled"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUpload disablePermissions />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display={"flex"} justifyContent={"end"}>
            <Button disabled={!deadline} onClick={() => handleAddEntry()}>
              Speichern
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const uebergabeMask = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="level"
            value={level}
            onChange={(e, value) => setLevel(value)}
            freeSolo
            autoSelect
            options={[
              "Allgemein",
              "UG",
              "EG",
              "OG",
              "DG",
              "Spitzboden",
              "Außenanlagen",
              "1.OG",
              "2.OG",
              "3.OG",
              "4.OG",
            ]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Level" variant="filled" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="room"
            value={room}
            onChange={(e, value) => setRoom(value)}
            freeSolo
            autoSelect
            options={[
              "Diele",
              "Gäste-WC",
              "Flur",
              "HWR",
              "Wohnzimmer",
              "Küche",
              "Bad",
              "Zimmer links",
              "Zimmer rechts",
            ]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zimmer"
                variant="filled"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label={"Bemerkung"}
            variant={"filled"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUpload disablePermissions />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display={"flex"} justifyContent={"end"}>
            <Button onClick={() => handleAddEntry()}>Speichern</Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const ProtocolTypes = {
    BEGEHUNG: begehungMask(),
    BESPRECHUNG: begehungMask(),
    FESTLEGUNG: begehungMask(),
    MAENGEL: maengelMask(),
    TAGESBERICHT: berichtMask(),
    UEBERGABE: uebergabeMask(),
  };

  return (
    <Box className={styles.fabContainer}>
      <Fab onClick={handleClick} color={"info"} className={styles.fab}>
        <VscAdd />
      </Fab>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Stack direction={"column"} p={1} spacing={1}>
          <Box p={1}>
            <Typography variant={"h6"}>Neuer Eintrag</Typography>
          </Box>
          <Box>{protocol && ProtocolTypes[protocol.type]}</Box>
        </Stack>
      </Dialog>
    </Box>
  );
};

export default NewEntry;
