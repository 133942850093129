import { useNavigate } from "react-router-dom";
import FullPageLoader from "../common/FullPageLoader";
import {
  Box,
  Button,
  Dialog,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useProtocols } from "../../apollo/hooks/useProtocols";

const ProtocolSections = {
  project: "PROJECT",
  field: "FIELD",
  building: "BUILDING",
  apartment: "APARTMENT",
};

const CreateProtocol = ({ section, id, menuItem, project, data }) => {
  const router = useNavigate();
  const {
    me,
    executeUserSelfQuery,
    loading: usersLoading,
    error: usersError,
  } = useUsers();
  const {
    protocols,
    executeProtocolQuery,
    addProtocol,
    loading: protocolsLoading,
    error: protocolsError,
  } = useProtocols();

  useEffect(() => {
    executeUserSelfQuery();
    executeProtocolQuery();
  }, []);

  if (usersLoading || protocolsLoading || !me || !protocols)
    return <FullPageLoader position={"relative"} />;
  if (usersError || protocolsError) return <FullPageLoader error />;

  const SelectProtocolType = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleAddProtocol = async (type) => {
      const payload = {
        user: me._id,
        disabled: false,
        type: type,
        section: ProtocolSections[section],
        project: project._id,
        field: ProtocolSections[section] === "FIELD" ? id : null,
        building: ProtocolSections[section] === "BUILDING" ? id : null,
        apartment: ProtocolSections[section] === "APARTMENT" ? id : null,
      };
      addProtocol(payload).then((res) => {
        router(`/protocols/${res.data.createProtocol._id}`);
      });
    };

    return (
      <Box>
        {menuItem ? (
          <MenuItem disableRipple onClick={handleClick}>
            <Typography>Neues Protokoll</Typography>
          </MenuItem>
        ) : (
          <Button onClick={handleClick}>+ Protokoll</Button>
        )}
        <Dialog
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple onClick={() => handleAddProtocol("BEGEHUNG")}>
            <Typography>Begehungsprotokoll</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disableRipple
            onClick={() => handleAddProtocol("BESPRECHUNG")}
          >
            <Typography>Besprechungsprotokoll</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disableRipple
            onClick={() => handleAddProtocol("FESTLEGUNG")}
          >
            <Typography>Festlegungsprotokoll</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disabled={
              protocols.filter(
                (protocol) =>
                  protocol[section]?._id === data._id &&
                  protocol.type === "MAENGEL",
              ).length > 0
            }
            disableRipple
            onClick={() => handleAddProtocol("MAENGEL")}
          >
            <Typography>Mängelprotokoll</Typography>
          </MenuItem>
          {ProtocolSections[section] === "PROJECT" && (
            <Divider style={{ margin: 0 }} />
          )}
          {ProtocolSections[section] === "PROJECT" && (
            <MenuItem
              disableRipple
              onClick={() => handleAddProtocol("TAGESBERICHT")}
            >
              <Typography>Bautagebericht</Typography>
            </MenuItem>
          )}
          {ProtocolSections[section] === "APARTMENT" &&
            (me.role === "ADMIN" ||
              me.role === "HAUSVERWALTUNG" ||
              me.role === "MANAGER") && <Divider style={{ margin: 0 }} />}
          {ProtocolSections[section] === "APARTMENT" &&
            (me.role === "ADMIN" ||
              me.role === "HAUSVERWALTUNG" ||
              me.role === "MANAGER") && (
              <MenuItem
                disableRipple
                onClick={() => handleAddProtocol("UEBERGABE")}
              >
                <Typography>Übergabeprotokoll</Typography>
              </MenuItem>
            )}
        </Dialog>
      </Box>
    );
  };

  return <SelectProtocolType />;
};

export default CreateProtocol;
