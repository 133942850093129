import { gql } from "@apollo/client";
import { FILE_ENTITY } from "../entities/fileEntity";
import { USER_ENTITY } from "../entities/userEntity";

export const ON_FILES_CHANGED = gql`
  subscription {
    onFilesChanged {
        ...FileEntity
        user {
          ...UserEntity
        }

    }
  }
  ${FILE_ENTITY},${USER_ENTITY}
`;
