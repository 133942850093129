import { gql } from "@apollo/client";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";

export const DISABLE_PROTOCOL = gql`
  mutation disableProtocol($input: ProtocolDisableInput!, $id: String!) {
    disableProtocol(input: $input, id: $id) {
      ...ProtocolEntity
    }
  }
  ${PROTOCOL_ENTITY}
`;
