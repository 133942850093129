import { Box, Breadcrumbs, Chip, Stack } from "@mui/material";
import { emphasize, styled } from "@mui/material/styles";
import FullPageLoader from "../common/FullPageLoader";
import { getPathById } from "../../findPathById";
import { useProjects } from "../../apollo/hooks/useProjects";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EditProject from "../EditForms/EditProject";
import EditField from "../EditForms/EditField";
import EditBuilding from "../EditForms/EditBuilding";
import EditApartment from "../EditForms/EditApartment";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const SectionPart = {
  ProjectEntity: "project",
  FieldEntity: "field",
  BuildingEntity: "building",
  ApartmentEntity: "apartment",
};

const Section = (props) => {
  const router = useNavigate();
  const {
    projects,
    executeProjectQuery,
    error: projectError,
    loading: projectLoading,
  } = useProjects();

  useEffect(() => {
    executeProjectQuery();
  }, []);

  if (projectError) return <FullPageLoader error />;
  if (projectLoading || !projects)
    return <FullPageLoader position={"relative"} />;

  const ProjectTree = () => {
    console.log(getPathById(projects, props.id));
    return (
      <Box p={1}>
        <Breadcrumbs>
          {getPathById(projects, props.id)?.map((path) => (
            <StyledBreadcrumb
              label={path.name}
              onClick={() =>
                router(
                  "/protocols/" + SectionPart[path.__typename] + "/" + path._id,
                )
              }
            />
          ))}
        </Breadcrumbs>
      </Box>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <ProjectTree />
    </Stack>
  );
};

export default Section;
