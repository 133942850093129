import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IoAddSharp } from "react-icons/io5";
import Fields from "./Fields";
import FullPageLoader from "../common/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import CreateProtocol from "../Protocols/CreateProtocol";
import { FieldName, ProjectName } from "../../types";
import { useProtocols } from "../../apollo/hooks/useProtocols";
import { useProjects } from "../../apollo/hooks/useProjects";
import { useFields } from "../../apollo/hooks/useFields";

const Projects = () => {
  const {
    projects,
    executeProjectQuery,
    error: projectError,
    loading: projectLoading,
  } = useProjects();
  const {
    protocols,
    executeProtocolQuery,
    error: protocolError,
    loading: protocolLoading,
  } = useProtocols();

  const routeParams = useParams();
  const router = useNavigate();

  useEffect(() => {
    executeProtocolQuery();
    executeProjectQuery();
  }, []);

  if (projectError || protocolError) return <FullPageLoader error />;
  if (projectLoading || protocolLoading || !protocols || !projects)
    return <FullPageLoader position={"relative"} />;

  const NewFieldForm = (props) => {
    const { addField } = useFields();
    const [fieldName, setFieldName] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleKeyPress = (e) => {
      if (e.keyCode === 13 && fieldName) {
        handleAddField();
      }
    };
    const handleAddField = () => {
      let payload = {
        name: fieldName,
        project: props.project._id,
      };
      addField(payload).then(() => {
        setFieldName("");
      });
    };
    return (
      <Box
        p={0}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "100%" }}
      >
        <Button color={"primary"} onClick={handleClick}>
          + {FieldName[props.project.type]}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <Stack direction={"row"} spacing={1} p={0.5}>
              <TextField
                variant={"filled"}
                onKeyDown={handleKeyPress}
                autoFocus
                size={"small"}
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
                label={FieldName[props.project.type] + " Bezeichnung"}
              />
              <Box>
                <Button
                  disabled={!fieldName}
                  sx={{ height: "100%" }}
                  size={"small"}
                  onClick={() => handleAddField()}
                >
                  <IoAddSharp />
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Popover>
      </Box>
    );
  };

  const EditProjectForm = (props) => {
    const { removeProject } = useProjects();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleRemoveProject = () => {
      if (
        window.confirm(
          `Möchtest du das Projekt "${props.project.name}" löschen?`,
        )
      ) {
        removeProject(props.project._id).then(() => {
          router("/projects");
        });
      }
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Fab size={"small"} color={"primary"} onClick={handleClick}>
          <BiDotsVerticalRounded />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <CreateProtocol
            section={"project"}
            id={props.project._id}
            project={props.project}
            data={props.project}
            menuItem
          />
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disableRipple
            onClick={() => router("/edit/project/" + props.project._id)}
          >
            <Typography>Bearbeiten</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem disableRipple onClick={handleRemoveProject}>
            <Typography color={"secondary"}>
              {props.project.name + " Löschen"}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {projects
        .filter((project) => project._id === routeParams.id)
        .map((project, index) => (
          <Paper key={index}>
            <Stack direction={"column"} spacing={1} p={1}>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction={"column"}>
                  <Typography variant={"h7"}>{project.name}</Typography>
                  <Typography
                    variant={"body2"}
                  >{`${ProjectName[project.type]} - ${protocols.filter((protocol) => protocol.project?._id === project._id).length} Protokoll(e)`}</Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Button
                    onClick={() => router("/protocols/project/" + project._id)}
                  >
                    Protokolle
                  </Button>
                  <EditProjectForm project={project} />
                </Stack>
              </Stack>
              <Box>
                <Grid container spacing={1}>
                  <Fields
                    fields={project.fields}
                    protocols={protocols}
                    project={project}
                  />
                  <Grid item xs={12} md={12}>
                    <NewFieldForm project={project} />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Paper>
        ))}
    </Stack>
  );
};

export default Projects;
