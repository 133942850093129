import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import styles from "./home.module.css";
import { useNavigate } from "react-router-dom";
import { shadeColor } from "../../shade";
import FullpageLoader from "../common/FullPageLoader";
import { CiBoxList, CiLogout, CiMap, CiSettings } from "react-icons/ci";
import { useEffect } from "react";
import { FaIdCard, FaKey } from "react-icons/fa";
import { useUsers } from "../../apollo/hooks/useUsers";

const Home = () => {
  const router = useNavigate();
  const {
    me,
    executeUserSelfQuery,
    loading: meLoading,
    error: meError,
  } = useUsers();

  useEffect(() => {
    console.log("execute");
    executeUserSelfQuery();
  }, []);

  if (meLoading || !me) return <FullpageLoader />;
  if (meError) return <FullpageLoader error />;

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    window.location.reload();
  };

  const navButtons = [
    {
      title: "Bauvorhaben",
      subtitle: "Bauvorhaben, Baufelder, Gebäude, Wohneinheiten",
      icon: <CiMap />,
      color: "#1495e5",
      action: () => router("/projects"),
      permissions: ["ADMIN", "BAULEITER", "MANAGER"],
      category: "Verwaltung",
      size: 6,
    },
    {
      title: "Meine Aufgaben",
      icon: <CiBoxList />,
      color: "#1495e5",
      action: () => router("/projects/select"),
      permissions: ["ADMIN", "BAULEITER", "MANAGER"],
      category: "Verwaltung",
      size: 6,
    },
    {
      title: "Objekte",
      subtitle: "Objekte, Bereiche, Gebäude, Wohneinheiten",
      icon: <FaKey />,
      color: "#40c935",
      action: () => router("/objects"),
      permissions: ["ADMIN", "HAUSVERWALTUNG", "MANAGER"],
      category: "Verwaltung",
      size: 12,
    },
    {
      title: "Kontakte und Ansprechpartner",
      icon: <FaIdCard />,
      color: "#efbf23",
      action: () => router("/contacts"),
      permissions: ["ADMIN", "HAUSVERWALTUNG", "BAULEITER", "MANAGER"],
      category: "Kontakte",
      size: 12,
    },
    {
      title: "Nutzerverwaltung",
      icon: <CiSettings />,
      color: "#a6b0be",
      action: () => router("/users"),
      permissions: ["ADMIN"],
      category: "Sonstiges",
      size: 6,
    },
    {
      title: "Abmelden",
      subtitle: "Angemeldet als " + me.prename + " " + me.surname,
      icon: <CiLogout />,
      color: "#ff667f",
      action: () => handleLogout(),
      permissions: ["ADMIN", "BAULEITER", "HAUSVERWALTUNG", "MANAGER"],
      category: "Sonstiges",
      size: 6,
    },
  ];

  let sortedNavButtons = navButtons
    .filter((button) => button.permissions.includes(me.role))
    .reduce(function (o, cur) {
      var occurs = o.find((ob) => ob.category === cur.category);
      if (occurs) {
        occurs.buttons.push(cur);
      } else {
        var obj = {
          category: cur.category,
          buttons: [cur],
        };
        o = o.concat(obj);
      }

      return o;
    }, []);

  const renderCategories = () => {
    return sortedNavButtons.map((button, index) => (
      <Box key={index} className={styles.categoryWrapper} p={1}>
        <Stack key={index} direction={"column"} spacing={1}>
          <Box px={1}>
            <Typography color={"#60656e"} variant={"h6"}>
              {button.category}
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={1}>
              {renderTasks(button.buttons)}
            </Grid>
          </Box>
        </Stack>
      </Box>
    ));
  };

  const renderTasks = (buttons) => {
    return buttons
      .filter((task) => !task.hide)
      .filter((task) => task.permissions.includes(me.role))
      .map((task, index) => (
        <Grid key={index} item xs={12} md={task.size}>
          {task.component ? (
            task.component
          ) : (
            <Box
              onClick={() => (task.action ? task.action() : "")}
              className={styles.navButton}
              display={"flex"}
              alignItems={"center"}
              p={0}
            >
              {task.ref && <Box className={styles.refBox} ref={task.ref} />}
              <Stack
                width={"100%"}
                direction={"row"}
                spacing={2}
                display={"flex"}
                alignItems={"center"}
              >
                <Card sx={{ borderRadius: "5px 0px 0px 5px" }}>
                  <CardContent
                    sx={{
                      background: `linear-gradient(90deg, ${task.color}, ${shadeColor(task.color, 5)})`,
                    }}
                  >
                    <Box p={1}>
                      <Box
                        className={styles.iconWrapper}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          style={{ lineHeight: "0px", zIndex: 3 }}
                          color={shadeColor(task.color, 150)}
                          variant={"h4"}
                        >
                          {task.icon}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <Stack direction={"column"}>
                  <Typography variant={"h7"}>{task.title}</Typography>
                  {task.subtitle && (
                    <Typography variant={"body2"}>{task.subtitle}</Typography>
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
        </Grid>
      ));
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {renderCategories()}
    </Stack>
  );
};

export default Home;
