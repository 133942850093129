import { gql } from "@apollo/client";
import { CONTACT_ENTITY } from "../../entities/contactEntity";
import { CUSTOMER_ENTITY } from "../../entities/customerEntity";
import { ENTRY_ENTITY } from "../../entities/entryEntity";

export const GET_CONTACT_BY_ID = gql`
  query getContactById($id: String!){
    getContactById(id: $id) {
        ...ContactEntity
        customer {
          ...CustomerEntity
        }
        entrys {
          ...EntryEntity
        }
    }
  }
  ${CONTACT_ENTITY},${CUSTOMER_ENTITY},${ENTRY_ENTITY}
`;
