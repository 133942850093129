import { gql } from "@apollo/client";

export const APARTMENT_ENTITY = gql`
  fragment ApartmentEntity on ApartmentEntity {
    _id
    name
    created_at
    updated_at
  }
`;
