import { gql } from "@apollo/client";
import { PROJECT_ENTITY } from "../../entities/projectEntity";
import { FIELD_ENTITY } from "../../entities/fieldEntity";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";

export const GET_FIELD_BY_ID = gql`
  query getFieldById($id: String!){
    getFieldById(id: $id) {
        ...FieldEntity
        project {
          ...ProjectEntity
        }
        buildings {
          ...BuildingEntity
        }
        protocols {
          ...ProtocolEntity
        }
    }
  }
  ${FIELD_ENTITY},${BUILDING_ENTITY},${PROJECT_ENTITY},${PROTOCOL_ENTITY}
`;
