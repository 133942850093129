import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_ENTRYS } from "../queries/Entry/getAllEntrys";
import { ON_ENTRYS_CHANGED } from "../subscriptions/onEntrysChanged";
import { CREATE_ENTRY } from "../mutations/Entry/createEntry";
import { UPDATE_ENTRY } from "../mutations/Entry/updateEntry";
import { REMOVE_ENTRY } from "../mutations/Entry/removeEntry";
import { UPDATE_ENTRY_STATE } from "../mutations/Entry/updateEntryState";
import { UPDATE_ENTRY_HIDDEN } from "../mutations/Entry/updateEntryHidden";
import { GET_ENTRY_BY_ID } from "../queries/Entry/getEntryById";

const query = GET_ALL_ENTRYS;
const createMutation = CREATE_ENTRY;
const updateMutation = UPDATE_ENTRY;
const removeMutation = REMOVE_ENTRY;
const updateStateMutation = UPDATE_ENTRY_STATE;
const updateHiddenMutation = UPDATE_ENTRY_HIDDEN;
const getByIdMutation = GET_ENTRY_BY_ID;
const subscription = ON_ENTRYS_CHANGED;

const entityName = "entry";

export const useEntries = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    updateState,
    { loading: updateStateMutationLoading, error: updateStateMutationError },
  ] = useMutation(updateStateMutation, {
    onCompleted: () => {
      console.log(entityName + " state updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    updateHidden,
    { loading: updateHiddenMutationLoading, error: updateHiddenMutationError },
  ] = useMutation(updateHiddenMutation, {
    onCompleted: () => {
      console.log(entityName + " hidden updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onEntrysChanged);
      queryRefetch();
    },
  });

  const executeEntryQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllEntrys);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      updateStateMutationLoading ||
      updateHiddenMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError ||
      updateStateMutationError ||
      updateHiddenMutationError ||
      getByIdMutationError,
    entries: data,
    executeEntryQuery,
    addEntry: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateEntry: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeEntry: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    updateEntryState: (input, id) =>
      updateState({
        variables: {
          input: input,
          id: id,
        },
      }),
    updateEntryHidden: (input, id) =>
      updateHidden({
        variables: {
          input: input,
          id: id,
        },
      }),
    getEntryById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
