import { useMails } from "../../apollo/hooks/useMails";
import { useEffect } from "react";
import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import FullPageLoader from "../common/FullPageLoader";
import moment from "moment";

const RenderMails = ({ mails }) => {
  return mails.map((mail, index) => (
    <Paper key={index}>
      <Box p={2}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"column"}>
            <Typography variant={"h7"}>{mail.subject}</Typography>
            <Typography variant={"body2"}>
              {"Gesendet am " + moment(mail.created_at).format("LLLL")}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
            <Typography variant={"body2"}>BCC:</Typography>
            {mail.bcc.map((bc, index) => (
              <Chip key={index} size={"small"} label={bc} />
            ))}
          </Stack>
        </Stack>
      </Box>
    </Paper>
  ));
};

const Mails = () => {
  const { mails, executeMailQuery, error: mailsError } = useMails();

  useEffect(() => {
    executeMailQuery();
  }, []);

  if (!mails) return <FullPageLoader position={"relative"} />;
  if (mailsError) return <FullPageLoader error />;

  return (
    <Stack direction={"column"} spacing={1}>
      <RenderMails mails={mails} />
    </Stack>
  );
};

export default Mails;
