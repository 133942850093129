import {
  Box,
  Card,
  CardContent,
  Divider,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./projects.module.css";
import { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateProtocol from "../Protocols/CreateProtocol";
import { useApartments } from "../../apollo/hooks/useApartments";

const Apartments = (props) => {
  const router = useNavigate();

  const EditApartmentForm = (props) => {
    const { removeApartment } = useApartments();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleRemoveApartment = () => {
      if (
        window.confirm(
          `Möchtest du die Wohneinheit "${props.apartment.name}" löschen?`,
        )
      ) {
        removeApartment(props.apartment._id);
      }
    };
    return (
      <Box
        textAlign={"right"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"end"}
        sx={{ height: "100%" }}
      >
        <Fab size={"small"} color={"primary"} onClick={handleClick}>
          <BiDotsVerticalRounded />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            disableRipple
            onClick={() =>
              router("/protocols/apartment/" + props.apartment._id)
            }
          >
            <Typography>Protokolle</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <CreateProtocol
            section={"apartment"}
            id={props.apartment._id}
            project={props.project}
            data={props.apartment}
            menuItem
          />
          <Divider style={{ margin: 0 }} />
          <MenuItem
            disableRipple
            onClick={() => router("/edit/apartment/" + props.apartment._id)}
          >
            <Typography>Bearbeiten</Typography>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
          <MenuItem disableRipple onClick={handleRemoveApartment}>
            <Typography color={"secondary"}>
              {props.apartment.name + " Löschen"}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return props.apartments.map((apartment, index) => (
    <Grid key={index} item xs={12} md={12}>
      <Card sx={{ height: "100%" }}>
        <CardContent
          sx={{ height: "100%" }}
          className={styles.apartmentWrapper}
        >
          <Stack direction={"column"} spacing={1} p={1} sx={{ height: "100%" }}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"column"}>
                <Typography variant={"h7"}>{apartment.name}</Typography>
                <Typography
                  variant={"body2"}
                >{`Wohneinheit - ${props.protocols.filter((protocol) => protocol.apartment?._id === apartment._id).length} Protokoll(e)`}</Typography>
              </Stack>
              <EditApartmentForm
                apartment={apartment}
                project={props.project}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  ));
};

export default Apartments;
