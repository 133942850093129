import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_CONTACTS } from "../queries/Contact/getAllContacts";
import { CREATE_CONTACT } from "../mutations/Contact/createContact";
import { UPDATE_CONTACT } from "../mutations/Contact/updateContact";
import { REMOVE_CONTACT } from "../mutations/Contact/removeContact";
import { ON_CONTACTS_CHANGED } from "../subscriptions/onContactsChanged";
import { GET_CONTACT_BY_ID } from "../queries/Contact/getContactById";

const query = GET_ALL_CONTACTS;
const createMutation = CREATE_CONTACT;
const updateMutation = UPDATE_CONTACT;
const removeMutation = REMOVE_CONTACT;
const getByIdMutation = GET_CONTACT_BY_ID;
const subscription = ON_CONTACTS_CHANGED;

const entityName = "contact";

export const useContacts = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onContactsChanged);
      queryRefetch();
    },
  });

  const executeContactQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllContacts);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError,
    contacts: data,
    executeContactQuery,
    addContact: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateContact: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeContact: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getContactById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
