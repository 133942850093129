import { gql } from "@apollo/client";
import { CUSTOMER_ENTITY } from "../../entities/customerEntity";
import { CONTACT_ENTITY } from "../../entities/contactEntity";

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: CustomerInput!, $id: String!) {
    updateCustomer(input: $input, id: $id) {
        ...CustomerEntity
        contacts {
          ...ContactEntity
        }
    }
  }
  ${CUSTOMER_ENTITY},${CONTACT_ENTITY}
`;
