import FullpageLoader from "../FullPageLoader";
import { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { shadeColor } from "../../../shade";
import { useContacts } from "../../../apollo/hooks/useContacts";

const Contact = (props) => {
  const {
    contacts,
    executeContactQuery,
    loading: contactsLoading,
    error: contactsError,
  } = useContacts();
  const [contact, setContact] = useState(null);

  useEffect(() => {
    executeContactQuery();
  }, []);

  useEffect(() => {
    if (contacts) {
      setContact(contacts.find((contact) => contact._id === props.contactId));
    }
  }, [props.contactId, contacts]);

  if (contactsLoading || !contact || !contacts)
    return <Chip icon={<BsFillPersonFill />} label={"Lade Daten..."} />;
  if (contactsError) return <FullpageLoader error position={"relative"} />;

  return (
    <Stack direction={"column"}>
      <Typography
        color={props.color && shadeColor(props.color.toString(), -50)}
      >
        {contact.prename + " " + contact.surname}
      </Typography>
      <Box>
        {contact.gewerk.length > 0 &&
          contact.gewerk.map((x, index) => (
            <Chip size={"small"} key={index} label={x} />
          ))}
      </Box>
      <Typography color={props.color && props.color} variant={"body2"}>
        {"Firma " + contact.customer.name}
      </Typography>
      {props.extend && (
        <Stack direction={"column"}>
          <Typography
            color={props.color && props.color}
            color={!contact.email && "secondary"}
            variant={"body2"}
          >
            {contact.email || "Keine Email Adresse hinterlegt."}
          </Typography>
          {contact.manager && (
            <Box>
              <Chip size={"small"} color={"primary"} label={"Bauleiter"} />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default Contact;
