import { gql } from "@apollo/client";

export const FIELD_ENTITY = gql`
  fragment FieldEntity on FieldEntity {
    _id
    name
    created_at
    updated_at
  }
`;
