// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customers_fab__ttEO\\+ {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: calc(20px);
  left: auto;
  position: fixed;
}
.customers_fabContainer__ncqoY {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  position: fixed;
  z-index: 999;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customers/customers.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;AACA;EACE,SAAS;EACT,QAAQ;EACR,SAAS;EACT,UAAU;EACV,eAAe;EACf,YAAY;AACd","sourcesContent":[".fab {\n  margin: 0px;\n  top: auto;\n  right: 20px;\n  bottom: calc(20px);\n  left: auto;\n  position: fixed;\n}\n.fabContainer {\n  top: auto;\n  right: 0;\n  bottom: 0;\n  left: auto;\n  position: fixed;\n  z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fab": `customers_fab__ttEO+`,
	"fabContainer": `customers_fabContainer__ncqoY`
};
export default ___CSS_LOADER_EXPORT___;
