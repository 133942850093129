import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../Home";
import Users from "../Users";
import Projects from "../Projects/Projects";
import Root from "../Projects/Root";
import BackButton from "../common/BackButton/BackButton";
import CreateProtocol from "../Protocols/CreateProtocol";
import EditSection from "../Section/EditSection";
import React from "react";
import { Stack } from "@mui/material";
import Protocols from "../Protocols/Protocols";
import Entrys from "../Entrys/Entrys";
import { AnimatePresence } from "framer-motion";
import PageTransition from "../../PageTransition";
import Customers from "../Customers";
import Mails from "../Mails/Mails";

const ContentRouter = () => {
  const location = useLocation();
  return (
    <Stack direction={"column"} spacing={1}>
      <AnimatePresence
        mode={"wait"}
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        {location.pathname !== "/" && <BackButton />}
        <Routes location={location} key={location.pathname}>
          <Route
            index
            element={
              <PageTransition>
                <Home />
              </PageTransition>
            }
          />
          <Route path="users">
            <Route
              index
              element={
                <PageTransition>
                  <Users />
                </PageTransition>
              }
            />
          </Route>
          <Route path="projects">
            <Route
              index
              element={
                <PageTransition>
                  <Root type={"project"} />
                </PageTransition>
              }
            />
            <Route
              path=":id"
              element={
                <PageTransition>
                  <Projects />
                </PageTransition>
              }
            />
            <Route
              path="select"
              element={
                <PageTransition>
                  <Root type={"project"} select />
                </PageTransition>
              }
            />
          </Route>
          <Route path="objects">
            <Route
              index
              element={
                <PageTransition>
                  <Root type={"object"} />
                </PageTransition>
              }
            />
            <Route
              path=":id"
              element={
                <PageTransition>
                  <Projects />
                </PageTransition>
              }
            />
          </Route>
          <Route path="edit">
            <Route
              path=":section/:id"
              element={
                <PageTransition>
                  <EditSection />
                </PageTransition>
              }
            />
          </Route>
          <Route path="contacts">
            <Route
              index
              element={
                <PageTransition>
                  <Customers />
                </PageTransition>
              }
            />
          </Route>
          <Route path="protocols">
            <Route
              path=":section/:id/"
              element={
                <PageTransition>
                  <Protocols />
                </PageTransition>
              }
            />
            <Route
              path=":section/:id/tasks"
              element={
                <PageTransition>
                  <Protocols myTasks />
                </PageTransition>
              }
            />
            <Route
              path=":section/:id/mails"
              element={
                <PageTransition>
                  <Mails />
                </PageTransition>
              }
            />
            <Route
              path=":protocolId"
              element={
                <PageTransition>
                  <Entrys noEdit />
                </PageTransition>
              }
            />
            <Route
              path=":section/:id/create"
              element={
                <PageTransition>
                  <CreateProtocol />
                </PageTransition>
              }
            />
          </Route>
        </Routes>
      </AnimatePresence>
    </Stack>
  );
};

export default ContentRouter;
