import { gql } from "@apollo/client";
import { CUSTOMER_ENTITY } from "../../entities/customerEntity";
import { CONTACT_ENTITY } from "../../entities/contactEntity";

export const GET_ALL_CUSTOMERS = gql`
  query getAllCustomers {
    getAllCustomers {
        ...CustomerEntity
        contacts {
          ...ContactEntity
        }
    }
  }
  ${CUSTOMER_ENTITY},${CONTACT_ENTITY}
`;
