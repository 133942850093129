// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entrys_fab__C0YVX {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: calc(20px);
  left: auto;
  position: fixed;
}
.entrys_fabContainer__XsfLE {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  position: fixed;
  z-index: 999;
}
.entrys_sigCanvas__cCZG7 {
  height: 200px;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
  border-radius: 5px;
}
.entrys_fileViewer__K3Pth {
  background: rgba(0,0,0,0.8);
}
`, "",{"version":3,"sources":["webpack://./src/views/Entrys/entrys.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;AACA;EACE,SAAS;EACT,QAAQ;EACR,SAAS;EACT,UAAU;EACV,eAAe;EACf,YAAY;AACd;AACA;EACE,aAAa;EACb,WAAW;EACX,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".fab {\n  margin: 0px;\n  top: auto;\n  right: 20px;\n  bottom: calc(20px);\n  left: auto;\n  position: fixed;\n}\n.fabContainer {\n  top: auto;\n  right: 0;\n  bottom: 0;\n  left: auto;\n  position: fixed;\n  z-index: 999;\n}\n.sigCanvas {\n  height: 200px;\n  width: 100%;\n  padding: 0;\n  margin: 0;\n  background: #fff;\n  border-radius: 5px;\n}\n.fileViewer {\n  background: rgba(0,0,0,0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fab": `entrys_fab__C0YVX`,
	"fabContainer": `entrys_fabContainer__XsfLE`,
	"sigCanvas": `entrys_sigCanvas__cCZG7`,
	"fileViewer": `entrys_fileViewer__K3Pth`
};
export default ___CSS_LOADER_EXPORT___;
