import { gql } from "@apollo/client";

export const MAIL_ENTITY = gql`
  fragment MailEntity on MailEntity {
    _id
    subject
    content
    bcc
    grace_period
    created_at
    updated_at
  }
`;
