import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_APARTMENTS } from "../queries/Apartment/getAllApartments";
import { CREATE_APARTMENT } from "../mutations/Apartment/createApartment";
import { UPDATE_APARTMENT } from "../mutations/Apartment/updateApartment";
import { REMOVE_APARTMENT } from "../mutations/Apartment/removeApartment";
import { ON_APARTMENTS_CHANGED } from "../subscriptions/onApartmentsChanged";
import { GET_APARTMENT_BY_ID } from "../queries/Apartment/getApartmentById";

const query = GET_ALL_APARTMENTS;
const createMutation = CREATE_APARTMENT;
const updateMutation = UPDATE_APARTMENT;
const removeMutation = REMOVE_APARTMENT;
const getByIdMutation = GET_APARTMENT_BY_ID;
const subscription = ON_APARTMENTS_CHANGED;

const entityName = "apartment";

export const useApartments = () => {
  const [skipQuery, setSkipQuery] = useState(true);
  const [data, setData] = useState(null);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery(query, {
    skip: skipQuery,
  });

  const [
    create,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(createMutation, {
    onCompleted: () => {
      console.log(entityName + " created!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    update,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useMutation(updateMutation, {
    onCompleted: () => {
      console.log(entityName + " updated!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    remove,
    { loading: removeMutationLoading, error: removeMutationError },
  ] = useMutation(removeMutation, {
    onCompleted: () => {
      console.log(entityName + " removed!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Mutation error:", error);
    },
  });

  const [
    getById,
    { loading: getByIdMutationLoading, error: getByIdMutationError },
  ] = useLazyQuery(getByIdMutation, {
    onCompleted: () => {
      console.log(entityName + " found!");
    },
    onError: (error) => {
      console.error("[" + entityName + "] Query error:", error);
    },
  });

  useSubscription(subscription, {
    onSubscriptionData: ({ subscriptionData }) => {
      setData(subscriptionData.data.onApartmentsChanged);
      queryRefetch();
    },
  });

  const executeApartmentQuery = () => {
    setSkipQuery(false);
  };

  useEffect(() => {
    if (queryData) {
      setData(queryData.getAllApartments);
    }
  }, [queryData]);

  return {
    loading:
      queryLoading ||
      createMutationLoading ||
      updateMutationLoading ||
      removeMutationLoading ||
      getByIdMutationLoading,
    error:
      queryError ||
      createMutationError ||
      updateMutationError ||
      removeMutationError ||
      getByIdMutationError,
    apartments: data,
    executeApartmentQuery,
    addApartment: (input) =>
      create({
        variables: {
          input: input,
        },
      }),
    updateApartment: (input, id) =>
      update({
        variables: {
          input: input,
          id: id,
        },
      }),
    removeApartment: (id) =>
      remove({
        variables: {
          id: id,
        },
      }),
    getApartmentById: (id) =>
      getById({
        variables: {
          id: id,
        },
      }),
  };
};
