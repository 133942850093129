import { gql } from "@apollo/client";
import { FIELD_ENTITY } from "../../entities/fieldEntity";
import { BUILDING_ENTITY } from "../../entities/buildingEntity";
import { APARTMENT_ENTITY } from "../../entities/apartmentEntity";
import { PROJECT_ENTITY } from "../../entities/projectEntity";
import { PROTOCOL_ENTITY } from "../../entities/protocolEntity";

export const GET_BUILDING_BY_ID = gql`
  query getBuildingById($id: String!){
    getBuildingById(id: $id) {
        ...BuildingEntity
        field {
          ...FieldEntity
            project {
              ...ProjectEntity
            }
        }
        apartments {
          ...ApartmentEntity
        }
        protocols {
          ...ProtocolEntity
        }
    }
  }
  ${BUILDING_ENTITY},${FIELD_ENTITY},${APARTMENT_ENTITY},${PROTOCOL_ENTITY},${PROJECT_ENTITY}
`;
